import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMonitor from '@clarketm/saga-monitor';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { SagaMiddlewareOptions } from 'redux-saga';
import { REACT_APP_ENV } from './env';
import { version, developmentMode } from '../package.json';
import createRootReducer from './reducers';
import { vh100 } from './utils/helpers';
import appSagas from './sagas';
export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME || '/',
});
const development = REACT_APP_ENV === 'develop' || REACT_APP_ENV === 'local';
const sagaConfig: SagaMiddlewareOptions = {};
const composer = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;
if (development) {
  sagaConfig.sagaMonitor = createSagaMonitor({
    level: 'log',
    actionDispatch: true,
  });
}
window.addEventListener('scroll', () => {
  setTimeout(() => {
    vh100();
  }, 50);
});
window.addEventListener('resize', () => {
  setTimeout(() => {
    vh100();
  }, 50);
});
vh100();
const sagaMiddleware = createSagaMiddleware(sagaConfig);
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];
const store = createStore(createRootReducer(history), undefined, composer(applyMiddleware(...middlewares)));
sagaMiddleware.run(appSagas);
if (developmentMode) {
  setTimeout(() => {
    let counter = 0;
    document.addEventListener('click', () => {
      setTimeout(() => {
        counter = 0;
      }, 3000);
      if (counter < 10) {
        counter++;
      } else {
        const state = JSON.stringify(store.getState());
        const a = document.createElement('a');
        const blob = new Blob([state], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const fileName = `signalchat_state_${new Date().toUTCString()}`;
        a.href = url;
        a.download = fileName;
        a.click();
        alert(`State is saved - ${fileName}`);
      }
    });
  }, 3000);
}
export default store;
