import { call, put, take, all, select, fork, delay, cancel, takeLatest, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import get from 'lodash/get';
import { FETCH_INCIDENT, fetchSuccess, fetchUpdate, fetchFailure } from '../actions/incident';
import { setOwner, showNotificationExpire, SHOW_NOTIFICATION_EXPIRE, INCIDENT_UPDATE, UPDATE_NOTICE } from '../actions/chat';
import { getApp, IAuthState } from '../reducers/app';
import API from '../utils/api';
import { getIncident, IIncident } from '../reducers/incident';
import pubnub from '../utils/pubnub';
import { IAction } from '../utils/redux-create-reducer';
import { setLoading, sessionExpired, SESSION_EXPIRED, SET_NETWORK_STATUS } from '../actions/app';
import { tabVisibility } from '../utils/helpers';
import { getChat, IChat } from '../reducers/chat';
import { sortByDates } from '../utils/helpers';
import devClips from '../_dev_clips.json';
import { getFeatureFlags, IFeatureFlags } from '../reducers/featureFlags';
export function* expirationTimer() {
  while (true) {
    const inc: IIncident = yield select(getIncident);
    const endSessionTime = moment(inc.created_at).add(55, 'minutes');
    const isNotificationTime = moment().isAfter(endSessionTime);
    if (isNotificationTime) {
      yield put(showNotificationExpire({ timestamp: endSessionTime.toISOString() }));
    }
    yield delay(1000);
  }
}
export function* sessionExpiredPage() {
  while (true) {
    const inc: IIncident = yield select(getIncident);
    const isSessionExpired = moment().isAfter(moment(inc.created_at).add(60, 'minutes'));
    if (isSessionExpired) {
      yield put(sessionExpired());
    }
    yield delay(1000);
  }
}
export function* incidentFetch(isRefresh?: boolean) {
  try {
    const { data }: { data: IIncidentAPI } = yield call(API.getIncident);
    const { token }: IAuthState = yield select(getApp);
    const { flags }: IFeatureFlags = yield select(getFeatureFlags);
    if (!isRefresh) {
      pubnub.configure(data);
    }
    if (flags.testClips) {
      data.clips = ([...devClips, ...(data.clips && data.clips.length ? data.clips : [])] || []) as any[];
    }
    const owner = (data.contacts || []).find(c => c.token === token);
    const events = (data.events || []).sort((a, b) =>
      sortByDates(get(a, 'payload.timestamp'), get(b, 'payload.timestamp')),
    );
    let newMessages = events;
    if (isRefresh) {
      const { list }: IChat = yield select(getChat);
      newMessages = events.filter(
        (evt: IEventAPI) =>
          ['EVENT_MESSAGE', 'EVENT_JOINED'].includes(evt.type) &&
          !list.some(
            (msg: IMessage) =>
              [
                'owner',
                'person',
                'join',
                'serviceEta',
                'serviceShare',
                'serviceFreeform',
                'serviceLocation',
                'serviceDamage',
                'serviceSafety',
                'serviceCause',
              ].includes(msg.messageType) &&
              moment(msg.data.timestamp).isSame(get(evt, 'payload.timestamp')) &&
              get(msg, 'data.text') === get(evt, 'payload.body'),
          ),
      );
    }
    yield put(setOwner(owner));
    yield put(setLoading(false));
    if (!isRefresh) {
      yield put(fetchSuccess(data));
    }
    yield all(newMessages.map(({ type, payload }: IAction) => put({ type: `@PUBNUB/${type}`, payload })));
    if (isRefresh) {
      yield put(fetchUpdate(data));
    }
    return data
  } catch (e) {
    console.error(e);
    yield put(fetchFailure(e));
    return null
  }
}
export function* incidentWorker() {
  yield incidentFetch();
  const expTimerTask = yield fork(expirationTimer);
  const SessionExpiredTask = yield fork(sessionExpiredPage);
  yield take(SHOW_NOTIFICATION_EXPIRE);
  yield cancel(expTimerTask);
  yield take(SESSION_EXPIRED);
  yield cancel(SessionExpiredTask);
}
export function* incidentFetchForNetworkUp({ payload: networkStatus }: IAction) {
  if (networkStatus) {
    yield incidentFetch(true);
  }
}

export default function* incident() {
  yield takeLatest(FETCH_INCIDENT, incidentWorker);
  yield takeLatest(SET_NETWORK_STATUS, incidentFetchForNetworkUp);
  while (true) {
    const isVisible = yield call(tabVisibility);

    if (isVisible) {
      const incidentData = yield incidentFetch(true);
      console.log("incidentData");
      console.log(incidentData);
    }
  }
}
