import { API_BASE_URL } from '../env';
import { IAddTime } from './api.d';
import { modifyResponse } from './QATools';
import pack from '../../package.json';
import axios from 'axios';

const p = pack as any;

export enum urls {
  addTime = '/incident/{incidentId}/add_time/',
  message = '/incident/{incidentId}/message/',
  callPolice = '/incident/{incidentId}/police/',
  ping = '/incident/{incidentId}/ping/',
  leftQ = '/incident/{incidentId}/leave_queue/',
  autoClose = '/incident/{incidentId}/auto_close/',
  disarm = '/incident/{incidentId}/disarm/',
  incident = '/incident/',
  incidentContact = '/incident-contact/{token}/',
  incidentContactAuth = '/incident-contact/{token}/auth/',
}

export const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  config => {
    const {
      url = '',
      headers: { __token = '', __incidentId = '' },
    } = config;
    config.url = url.replace(/{token}/, __token).replace(/{incidentId}/, __incidentId);
    delete config.headers.__incidentId;
    delete config.headers.__token;

    return config;
  },
  error => Promise.reject(error),
);

if (p.developmentMode) {
  api.interceptors.response.use(response => {
    const { method, url } = response.config;
    let resp = response;
    if (method === 'get' && url === '/incident/') {
      resp = modifyResponse(response);
    }
    return resp;
  });
}

export const postGetIncidentContactAuth = (token: string) => {
  return api.post<IIncidentContact>(urls.incidentContactAuth.replace(/{token}/, token));
};


export const getGetIncidentContact = (token: string) => {
  return api.get<IContactAPI>(urls.incidentContact.replace(/{token}/, token));
};

export const getIncident = () => {
  return api.get<IIncidentAPI>(urls.incident);
};

export const postAddTime = () => {
  return api.post<IAddTime>(urls.addTime);
};
export const postPing = () => {
  return api.post<IIncidentAPI>(urls.ping);
};
export const postLeftQ = () => {
  return api.post<IIncidentAPI>(urls.leftQ);
};
export const postAutoClose = () => {
  return api.post<IIncidentAPI>(urls.autoClose);
};
export const postMessage = (data: { body: string; timestamp: string }) => {
  return api.post(urls.message, data);
};
export const postCallPolice = (data: {
  end_user_share_data: boolean;
  end_user_responding: boolean;
  end_user_eta: number;
  free_form_text?: string;
  end_user_people_location: boolean;
  end_user_property_damage: boolean;
  end_user_concern_for_safety: boolean;
  end_user_unsure_of_cause: boolean;
}) => {
  return api.post(urls.callPolice, { ...data, timestamp: new Date() });
};

export const postDisarm = () => {
  return api.post(urls.disarm, { timestamp: new Date() });
};

export default {
  urls,
  postCallPolice,
  postMessage,
  postAddTime,
  postDisarm,
  postPing,
  postLeftQ,
  postAutoClose,
  getGetIncidentContact,
  postGetIncidentContactAuth,
  getIncident,
};
