import { call, put, race } from 'redux-saga/effects';
import pubnub from '../utils/pubnub';
import { setNetworkStatus } from '../actions/app';

const onUp = () =>
  new Promise(s => {
    pubnub.setNetworkUpHandlers(s);
  });

const onDown = () =>
  new Promise(s => {
    pubnub.setNetworkDownHandlers(s);
  });

export default function* network() {
  while (true) {
    try {
      const { up, down } = yield race({
        up: call(onUp),
        down: call(onDown),
      });
      if (up === 'up') {
        yield put(setNetworkStatus(true));
      }
      if (down === 'down') {
        yield put(setNetworkStatus(false));
      }
    } catch (e) {
      console.error(e);
    }
  }
}
