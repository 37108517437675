import {
  REACT_APP_LINKS__CALL_ADT,
  REACT_APP_LINKS__TERMS_OF_SERVICE,
  REACT_APP_LINKS__FAQ,
  REACT_APP_LINKS__PRIVACY_POLICY,
  REACT_APP_LINKS__MYADT,
  REACT_APP_COMMERCIAL_LINKS__MYADT,
  REACT_APP_COMMERCIAL_LINKS__CALL_ADT,
  REACT_APP_COMMERCIAL_LINKS__TERMS_OF_SERVICE,
  REACT_APP_COMMERCIAL_LINKS__FAQ,
  REACT_APP_COMMERCIAL_LINKS__PRIVACY_POLICY,
  REACT_APP_RESICOM_LINKS__MYADT,
  REACT_APP_RESICOM_LINKS__CALL_ADT,
  REACT_APP_RESICOM_LINKS__TERMS_OF_SERVICE,
  REACT_APP_RESICOM_LINKS__FAQ,
  REACT_APP_RESICOM_LINKS__PRIVACY_POLICY,
} from './env';

export const helpfulLinksResidential = {
  call: {
    title: 'Call ADT',
    url: `tel:${REACT_APP_LINKS__CALL_ADT}`,
  },
  terms: {
    id: 2,
    title: 'Terms of Service',
    url: `${REACT_APP_LINKS__TERMS_OF_SERVICE}`,
  },
  faq: {
    id: 3,
    title: 'FAQ',
    url: `${REACT_APP_LINKS__FAQ}`,
  },
  privacy: {
    id: 4,
    title: 'Privacy Policy',
    url: `${REACT_APP_LINKS__PRIVACY_POLICY}`,
  },
};

export const helpfulLinksCommercial = {
  call: {
    title: 'Call ADT',
    url: `tel:${REACT_APP_COMMERCIAL_LINKS__CALL_ADT}`,
  },
  terms: {
    id: 2,
    title: 'Terms of Service',
    url: `${REACT_APP_COMMERCIAL_LINKS__TERMS_OF_SERVICE}`,
  },
  faq: {
    id: 3,
    title: 'FAQ',
    url: `${REACT_APP_COMMERCIAL_LINKS__FAQ}`,
  },
  privacy: {
    id: 4,
    title: 'Privacy Policy',
    url: `${REACT_APP_COMMERCIAL_LINKS__PRIVACY_POLICY}`,
  },
};

export const helpfulLinksResiCom = {
  call: {
    title: 'Call ADT',
    url: `tel:${REACT_APP_RESICOM_LINKS__CALL_ADT}`,
  },
  terms: {
    id: 2,
    title: 'Terms of Service',
    url: `${REACT_APP_RESICOM_LINKS__TERMS_OF_SERVICE}`,
  },
  faq: {
    id: 3,
    title: 'FAQ',
    url: `${REACT_APP_RESICOM_LINKS__FAQ}`,
  },
  privacy: {
    id: 4,
    title: 'Privacy Policy',
    url: `${REACT_APP_RESICOM_LINKS__PRIVACY_POLICY}`,
  },
};

export const myadtLink = `${REACT_APP_LINKS__MYADT}`;
export const myadtLinkCommercial = `${REACT_APP_COMMERCIAL_LINKS__MYADT}`;
export const myadtLinkResiCom = `${REACT_APP_RESICOM_LINKS__MYADT}`;

export const routes = {
  root: '/',
  defaultCustomer: '/:hash',
  otherCustomer: '/:customerType/:hash',
};
