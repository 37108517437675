import { takeLatest, call, put, select } from 'redux-saga/effects';
import { ADD_TIME, PING } from '../actions/timer';
import { postAddTime, postPing } from '../utils/api';
import { setLoading } from '../actions/app';
import { IAction } from '../utils/redux-create-reducer';
import { incidentFetch } from './incident';
import { INCIDENT_UPDATE } from '../actions/chat';
import { getIncident } from '../reducers/incident';

export function* addTimeWorker(payload: IAction) {
  yield put(setLoading(true));
  yield call(postAddTime);
  yield put(setLoading(false));
}

export function* pingWorker() {
  yield call(postPing);
  yield call(incidentFetch, true);
  const inc = yield select(getIncident);

  yield put({ type: INCIDENT_UPDATE, payload: { status: inc.status } });
}

export default function* timer() {
  yield takeLatest(ADD_TIME, addTimeWorker);
  yield takeLatest(PING, pingWorker);
}
