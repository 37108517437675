import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import EyeMessage from '../../components/MessageEye';
import { shareVideo } from '../../actions/confirm';
import { ChatLogo } from '../../components/ChatLogo';

export const CONFIRM_YES = 'alarmConfirmation--yes';
export const CONFIRM_NO = 'alarmConfirmation--no';

const SHARE_VIDEO_QUESTION = () => (
  <Trans i18nKey="shareVideo--question">
    <strong />
  </Trans>
);

const ShareVideo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const share = (isShared: boolean) => (e: React.SyntheticEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(shareVideo(isShared));
  };
  return (
    <>
      <div className={cx('ShareVideo--root')}>
        <ChatLogo />
        <Container className={cx('ShareVideo--confirmMessage')} expand="lg">
          <Row className={cx('ShareVideo--confirmHeader')}>
            <Col>{SHARE_VIDEO_QUESTION()}</Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Button className={'ShareVideo--b'} onClick={share(false)} color="light" block>
                {t(CONFIRM_NO)}
              </Button>
            </Col>
            <Col xs={6}>
              <Button className={'ShareVideo--b'} onClick={share(true)} color="light" block>
                {t(CONFIRM_YES)}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={'ShareVideo--eyeMessage'}>
        <EyeMessage />
      </div>
    </>
  );
};

export default ShareVideo;
