import React from 'react';
import { useTranslation } from 'react-i18next';
import { myadtLink } from '../../constants';
import HelpfulLinks from '../HelpfulLinks';
import useCustomerType from '../../hooks/use-customer-type';

export const SESSION_EXPIRE_START = 'session-expired-start';
export const SESSION_EXPIRE_END = 'session-expired-end';
export const SESSION_EXPIRED = 'session-expired';

const SessionExpiredPage = () => {
  const [t] = useTranslation();
  const { isCommercial, images } = useCustomerType();

  const nationalText = (
    <>
      {t(SESSION_EXPIRE_START)}
      <a target="_blank" rel="noopener noreferrer" href={myadtLink}>
        MyADT
      </a>
      {t(SESSION_EXPIRE_END)}
    </>
  );
  return (
    <>
      <div className={'SessionExpiredPage--root'}>
        <img src={images.logo} alt="icon" />
        <div className={'SessionExpiredPage--content'}>
          <div className={'SessionExpiredPage--title'}>{isCommercial ? t(SESSION_EXPIRED) : nationalText}</div>
        </div>
        <div className={'SessionExpiredPage--helpfulLinks'}>
          <HelpfulLinks />
        </div>
      </div>
    </>
  );
};

export default SessionExpiredPage;
