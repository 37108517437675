import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { convertName } from '../../utils/helpers';

interface IProps {
  timestamp: Date | string;
  isTimestampHidden?: boolean;
  name: string;
  isIconHidden?: boolean;
  message: string;
}

export const Person = ({ message, timestamp, name, isIconHidden, isTimestampHidden }: IProps) => {
  const timeline = (
    <>
      <div className={'MessagePerson--span'}></div>
      <div className={cx('MessagePerson--time', 'text-grey2')}>
        {name} at {moment(timestamp).format('LT')}
      </div>
    </>
  );

  return (
    <div className={'MessagePerson--root'}>
      <div className={'MessagePerson--timeline'}>{isTimestampHidden && timeline}</div>
      <div className={'MessagePerson--messageline'}>
        <div className={'MessagePerson--icon'}>
          {isIconHidden && (
            <div className={cx('contact-icon', { ['MessagePerson--textWrapper']: convertName(name).includes('WW') })}>
              {convertName(name)}
            </div>
          )}
        </div>
        <div className={'MessagePerson--message' + ' text-left'}>{message}</div>
      </div>
    </div>
  );
};

const MessagePerson = ({ data, id, className, isFirstMessage, isLastMessage }: IMessagePeace) => {
  const { text, user, timestamp } = data as IUserMessageData;
  return (
    <div id={id} className={className}>
      <Person
        message={text}
        name={user.name}
        timestamp={timestamp}
        isIconHidden={(!isLastMessage && isFirstMessage) || (isLastMessage && isFirstMessage)}
        isTimestampHidden={(!isFirstMessage && isLastMessage) || isLastMessage}
      />
    </div>
  );
};

export default MessagePerson;
