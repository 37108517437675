import createReducer from '../utils/redux-create-reducer';
import { UPDATE_INPUT, INPUT_CLEAR, SET_KEYBOARD_STATE } from '../actions/input';

export interface IInput {
  message: string;
  isKeyboardVisible: boolean;
}

interface IState {
  input: IInput;
}

export const initialState: IInput = { message: '', isKeyboardVisible: false };

export const getInput = (state: IState): IInput => state.input;

export default createReducer(initialState, {
  [UPDATE_INPUT]: message => ({ message }),
  [INPUT_CLEAR]: () => initialState,
  [SET_KEYBOARD_STATE]: isKeyboardVisible => ({ isKeyboardVisible: isKeyboardVisible.payload }),
});
