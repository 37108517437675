/* eslint-disable @typescript-eslint/camelcase */
import { call, put, take, all, select, delay } from 'redux-saga/effects';
import { inputSubmit } from '../actions/input';
import {
  SERVICE_MSG_SHARE,
  SERVICE_MSG_ETA,
  SERVICE_FREEFORM_SHARE,
  SERVICE_MSG_LOCATION, SERVICE_MSG_DAMAGE, SERVICE_MSG_SAFETY, SERVICE_MSG_CAUSE
} from '../utils/chatFeedHelpers';
import {
  CONFIRM_CANCEL_ACTION,
  SHARE_VIDEO_ACTION,
  ETA_ACTION,
  ALARM_SCORING,
  RESPONDING_ACTION,
  CONFIRM_VERIFY_ACTION,
  showETA,
  showAlarmScoring,
  closeAlarmScoring,
  closeETA,
  showShareVideo,
  showResponging,
  showFreeForm,
  closeFreeForm,
  closeResponging,
  closeShareVideo,
  FREEFORM, callPolice,
} from '../actions/confirm';
import { getIncident, IIncident } from '../reducers/incident';
import { getConfirm, IConfirm } from '../reducers/confirm';
import { postCallPolice, postDisarm } from '../utils/api';
import { getFeatureFlags, IFeatureFlags } from '../reducers/featureFlags';

export function* callPoliceAgregator() {
  const {
    eta: end_user_eta,
    isShareData: end_user_share_data,
    isResponding: end_user_responding,
    freeformtext: free_form_text,
    endUserPeopleLocation: end_user_people_location,
    endUserPropertyDamage: end_user_property_damage,
    endUserConcernForSafety: end_user_concern_for_safety,
    endUserUnsureOfCause: end_user_unsure_of_cause,
  }: IConfirm = yield select(getConfirm);

  const { customerTypes, flags }: IFeatureFlags = yield select(getFeatureFlags);
  const maybeFreeform = customerTypes.isCommercial ? { free_form_text } : {};

  const options = {
    end_user_share_data,
    end_user_responding,
    end_user_eta,
    end_user_people_location,
    end_user_property_damage,
    end_user_concern_for_safety,
    end_user_unsure_of_cause,
    ...maybeFreeform
  };

  try {
    yield call(postCallPolice, options);
    yield delay(300);

    if (end_user_responding) {
      yield put(inputSubmit(`${SERVICE_MSG_ETA}|${end_user_eta}`));
      yield delay(300);
    }
    if (customerTypes.isCommercial && free_form_text && flags.freeFormNotification) {
      yield put(inputSubmit(`${SERVICE_FREEFORM_SHARE}|${free_form_text}`));
      yield delay(300);
    }
    if (end_user_share_data) {
      yield put(inputSubmit(SERVICE_MSG_SHARE));
      yield delay(300);
    }
    if (end_user_people_location) {
      yield put(inputSubmit(SERVICE_MSG_LOCATION));
      yield delay(300);
    }
    if (end_user_property_damage) {
      yield put(inputSubmit(SERVICE_MSG_DAMAGE));
      yield delay(300);
    }
    if (end_user_concern_for_safety) {
      yield put(inputSubmit(SERVICE_MSG_SAFETY));
      yield delay(300);
    }
    if (end_user_unsure_of_cause) {
      yield put(inputSubmit(SERVICE_MSG_CAUSE));
      yield delay(300);
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* alarm() {
  while (true) {
    try {
      const { type, payload } = yield take([
        CONFIRM_VERIFY_ACTION,
        CONFIRM_CANCEL_ACTION,
        SHARE_VIDEO_ACTION,
        ETA_ACTION,
        ALARM_SCORING,
        RESPONDING_ACTION,
        FREEFORM,
      ]);
      let userEtaFeature = false
      const { customerTypes }: IFeatureFlags = yield select(getFeatureFlags);
      const incident: IIncident = yield select(getIncident);
      console.log("src/sagas/incident");
      console.log(incident);
      if (incident && incident.signalPayload) {
          userEtaFeature = incident.signalPayload.alarm_scoring || false;
      }
      console.log("alarm scoring bool");
      console.log(userEtaFeature);
      const confirmState: IConfirm = yield select(getConfirm);

      // ========================================================
      //            ALARM
      //         /         \
      //      CANCEL      VERIFY
      //      /              \
      //  CANCELED        - RESPONDING +
      //                /                 \
      //          SHARE VIDEO             ETA
      //              /                     \
      //          ALARMED                ALARM SCORING
      //                                    \
      //                                  SHARE VIDEO
      //                                      \
      //                              INPUT (car, color, model) (only if commercial)
      //                                         \
      //                                       ALARMED
      //                                      \
      //                              INPUT (car, color, model) (only if commercial)
      //                                         \
      //                                       ALARMED
      // =============================================================

      switch (type) {
        case CONFIRM_CANCEL_ACTION: {
          console.log("alarm 1");
          yield call(postDisarm);
          break;
        }
        case CONFIRM_VERIFY_ACTION: {
          if (userEtaFeature) {
            if (payload) {
              console.log("alarm 2");
              yield put(showETA());
            } else {
              if (incident.can_clip || incident.can_liveview === 'true') {
                console.log("alarm 3");
                yield put(showShareVideo());
              } else {
                console.log("alarm 4");
                yield call(callPoliceAgregator);
                yield put(callPolice());
              }
              console.log("alarm 5");
              yield put(closeResponging());
            }
          } else {
            console.log("alarm 6");
            yield put(showETA());
          }
          break;
        }
        case RESPONDING_ACTION: {
          if (payload) {
            console.log("alarm 7");
            yield put(showETA());
          } else {
            if (incident.can_clip || incident.can_liveview === 'true') {
              console.log("alarm 8");
              yield put(showShareVideo());
            } else {
              console.log("alarm 9");
              yield call(callPoliceAgregator);
              yield put(callPolice());
            }
            console.log("alarm 10");
            yield put(closeResponging());
          }
          break;
        }
        case ETA_ACTION: {
          if (userEtaFeature) {
            console.log("alarm 11");
            yield put(showAlarmScoring());
            yield put(closeETA());
            break;
          }  else {
            if (incident.can_clip || incident.can_liveview === 'true') {
              console.log("alarm 18");
              yield put(showShareVideo());
            } else if (customerTypes.isCommercial) {
              console.log("alarm 19");
              yield put(showFreeForm());
              yield put(closeETA());
            } else {
              console.log("alarm 20");
              yield call(callPoliceAgregator);
              yield put(closeETA());
            }
            break;
          }
        }
        //Alarm Scoring
        case ALARM_SCORING: {
          console.log("Alarm scoring 1")
          if (incident.can_clip || incident.can_liveview === 'true') {
            console.log("Alarm scoring 2")
            yield put(showShareVideo());
            yield put(closeAlarmScoring());
          } else if (customerTypes.isCommercial) {
            console.log("Alarm scoring 3")
            yield put(showFreeForm());
            yield put(closeAlarmScoring());
          } else {
            console.log("Alarm scoring 4")
            yield call(callPoliceAgregator);
            yield put(closeAlarmScoring());
          }
          break;
        }
        case FREEFORM: {
          console.log("alarm 14");
          yield call(callPoliceAgregator);
          yield put(closeFreeForm());
          yield put(callPolice())
          break;
        }
        case SHARE_VIDEO_ACTION: {
          if (confirmState.isResponding && customerTypes.isCommercial) {
            console.log("alarm 15");
            yield put(showFreeForm());
          } else {
            console.log("alarm 16");
            yield call(callPoliceAgregator);
            yield put(callPolice())
          }
          console.log("alarm 17");
          yield put(closeShareVideo());
          break;
        }
        default:
          console.log('def');
      }
    } catch (e) {
      console.error(e);
    }
  }
}
