import createReducer from '../utils/redux-create-reducer';
import {
  REQUEST_ACTION,
  CONFIRM_CANCEL_ACTION,
  CONFIRM_VERIFY_ACTION,
  DENY_ACTION,
  RESPONDING_ACTION,
  ETA_ACTION,
  ALARM_SCORING,
  SHARE_VIDEO_ACTION,
  FREEFORM, alarmScoring,
} from '../actions/confirm';

export interface IConfirm {
  cancelAlarmRequested: boolean;
  verifyAlarmRequested: boolean;
  cancelAlarmConfirmed: boolean;
  verifyAlarmConfirmed: boolean;
  isAlarmProcessed: boolean;
  isShareData: boolean;
  isResponding: boolean;
  freeformtext: string;
  eta: number;
  endUserPeopleLocation: boolean;
  endUserPropertyDamage: boolean;
  endUserConcernForSafety: boolean;
  endUserUnsureOfCause: boolean;
}

interface IState {
  confirm: IConfirm;
}

const ALARM_SCORING_SAFETY = "threat to life";
const ALARM_SCORING_LOCATION = "at location";
const ALARM_SCORING_DAMAGE = "evidence of property damage";
const ALARM_SCORING_UNSURE = "unsure what caused the alarm";

export const getConfirm = (state: IState): IConfirm => state.confirm;

export const initialState: IConfirm = {
  cancelAlarmRequested: false,
  verifyAlarmRequested: false,
  cancelAlarmConfirmed: false,
  verifyAlarmConfirmed: false,
  isAlarmProcessed: false,
  isShareData: false,
  isResponding: false,
  freeformtext: '',
  eta: 0,
  endUserPeopleLocation: false,
  endUserPropertyDamage: false,
  endUserConcernForSafety: false,
  endUserUnsureOfCause: false,
};

export default createReducer(initialState, {
  [REQUEST_ACTION]: (isVerified: boolean) => ({ cancelAlarmRequested: !isVerified, verifyAlarmRequested: isVerified }),
  [CONFIRM_CANCEL_ACTION]: () => ({ cancelAlarmConfirmed: true, verifyAlarmConfirmed: false, isAlarmProcessed: true }),
  [CONFIRM_VERIFY_ACTION]: () => ({ cancelAlarmConfirmed: false, verifyAlarmConfirmed: true, isAlarmProcessed: true }),
  [DENY_ACTION]: () => initialState,
  [RESPONDING_ACTION]: isResponding => ({ isResponding }),
  [ETA_ACTION]: (eta) => {
  console.log("ETA Value:", eta);
  let isResponding = true;
  // Parse the string to a number
  if (eta == 'I\'m Not Responding' || eta == 'Not Responding') {
    isResponding = false
  }
  console.log("Responding Value:", isResponding);
  return {
    eta: eta,
    isResponding: isResponding
  };
  },
  [ALARM_SCORING]: alarmScoring => {
    console.log("alarm scoring values:", alarmScoring);
    const locationKeyword = "location";
    const unsureKeyword = "unsure";
    // Function to normalize strings by removing special characters and converting to lowercase
    const endUserPeopleLocation = alarmScoring.some((element: string) => element.includes(locationKeyword));
    // const endUserPeopleLocation = alarmScoring.includes(ALARM_SCORING_LOCATION);
    const endUserPropertyDamage = alarmScoring.includes(ALARM_SCORING_DAMAGE);
    const endUserConcernForSafety = alarmScoring.includes(ALARM_SCORING_SAFETY);
    // const endUserUnsureOfCause = alarmScoring.includes(ALARM_SCORING_UNSURE);
    const endUserUnsureOfCause = alarmScoring.some((element: string) => element.includes(unsureKeyword));


    console.log("endUserPeopleLocation:", endUserPeopleLocation);
    console.log("endUserPropertyDamage:", endUserPropertyDamage);
    console.log("endUserConcernForSafety:", endUserConcernForSafety);
    console.log("endUserUnsureOfCause:", endUserUnsureOfCause);

    return {
        endUserPeopleLocation,
        endUserPropertyDamage,
        endUserConcernForSafety,
        endUserUnsureOfCause,
    };
    },
  [FREEFORM]: freeformtext => ({ freeformtext }),
  [SHARE_VIDEO_ACTION]: isShareData => ({ isShareData }),
});
