import { createAction, createEmptyAction } from '../utils/actions';

export interface IAuthObject {
  token?: string;
  bearerToken?: string;
  incidentId?: number;
}

export const AUTHORIZE = 'APP/AUTHORIZE';
export const CONFIRM_TERMS_AND_CONDTITIONS = 'APP/CONFIRM_TERMS_AND_CONDTITIONS';
export const SET_LOADING = 'APP/SET_LOADING';
export const INITIAL_READ = 'APP/INITIAL_READ';
export const INITIALIZE = 'APP/INITIALIZE';
export const SESSION_EXPIRED = 'APP/SESSION_EXPIRED';
export const SET_NETWORK_STATUS = 'APP/SET_NETWORK_STATUS';
export const SET_MEDIA = 'APP/SET_MEDIA';

export const LS_USER_CONFIRMATION = 'LS_USER_CONFIRMATION';

export const types = {
  AUTHORIZE,
  CONFIRM_TERMS_AND_CONDTITIONS,
  SET_LOADING,
  INITIAL_READ,
  INITIALIZE,
  SET_NETWORK_STATUS,
  SET_MEDIA,
};

export const authorize = createAction<IAuthObject>(AUTHORIZE);
export const setLoading = createAction<boolean>(SET_LOADING);
export const confirmTerms = createEmptyAction(CONFIRM_TERMS_AND_CONDTITIONS);
export const initRead = createEmptyAction(INITIAL_READ);
export const initialize = createEmptyAction(INITIALIZE);
export const sessionExpired = createEmptyAction(SESSION_EXPIRED);
export const setNetworkStatus = createAction<boolean>(SET_NETWORK_STATUS);
export const setMedia = createAction<IClipAPI | null>(SET_MEDIA);

export default {
  authorize,
  setLoading,
  confirmTerms,
  initRead,
  initialize,
  sessionExpired,
  setNetworkStatus,
  setMedia,
};
