import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerType from '../../hooks/use-customer-type';
export const PAGE_NOT_FOUND = '404';

const PageNotFound = () => {
  const [t] = useTranslation();

  const { isCommercial, images } = useCustomerType();

  return (
    <>
      <div className={'PageNotFound--root'}>
        <img src={images.logo} alt="icon" style={isCommercial ? { width: '190px' } : {}} />
        <div className={'PageNotFound--content'}>
          <div className={'PageNotFound--title'}>{t(PAGE_NOT_FOUND)}</div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
