import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { IAuthState, getApp } from '../../reducers/app';
import Chat from '../../containers/Chat';
import TermsAndConditions from '../../containers/TermsAndConditions';
import MediaPage from '../../containers/Media';
import Reconnecting from '../../components/Reconnecting';
import Preloader from '../../components/Preloader';
import PageNotFound from '../../components/404';
import SessionExpiredPage from '../../components/SessionExpiredPage';
import { getTitleKey, isCorrectURL } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

const Root = (): React.ReactElement => {
  const {
    isLoading,
    isSessionExpired,
    authorized,
    isTermsConfirmed,
    isInitialized,
    clip,
    isDisconnected,
  }: IAuthState = useSelector(getApp, shallowEqual);
  const isCorrectRoute = isCorrectURL(window.location.pathname);
  const titleKey = getTitleKey(window.location.pathname);
  const [t] = useTranslation();

  useEffect(() => {
    window.document.title = t(titleKey);
  }, []);
  
  const termsView = (
    <>
      {isLoading && <Preloader />}
      <TermsAndConditions />
    </>
  );

  const normalView = clip ? (
    <MediaPage clip={clip} />
  ) : (
    <>
      {isLoading && <Preloader />}
      <Chat />
      {isDisconnected && <Reconnecting />}
    </>
  );

  const content = isTermsConfirmed ? normalView : termsView;

  if ((!authorized && !isSessionExpired && isInitialized) || !isCorrectRoute) {
    return <PageNotFound />;
  }

  if (isSessionExpired) {
    return <SessionExpiredPage />;
  }

  return content;
};

export default Root;
