import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EyeIndicatorIcon } from '../../assets/icons/eye.svg';

export const ALARM_VISIBILITY = 'alarm-message-visibility';
const MessageEye = () => {
  const [t] = useTranslation();
  return (
    <div className={cx('MessageEye--eye')}>
      <EyeIndicatorIcon className={'MessageEye--eyeIcon'} />
      <div>{t(ALARM_VISIBILITY)}</div>
    </div>
  );
};

export default MessageEye;
