// libs
import React, { FC, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import cx from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import moment from 'moment';

// actions
import { read } from '../../actions/chat';

// reducers
import { getChat } from '../../reducers/chat';

// components
import NewMessages from '../../components/NewMessages';
import BotMessage from '../../components/MessageBot';
import PersonMessage from '../../components/MessagePerson';
import OwnerMessage from '../../components/MessageOwner';
import JoinMessage from '../../components/MessageJoin';
import MessageAlertStatus from '../../components/MessageAlertStatus';
import PersonActionsNotifications from '../../components/PersonActionsNotifications';
import ServiceMessage from '../../components/ServiceMessage';
import ExtendedTimeNotification from '../../components/TimeExtendedNotification';
import UpdateNotice from '../../components/UpdateNotice';

// containers
import ConfirmMessage from '../AlarmConfirmationMessage';
import RespondingMessage from '../Responding';
import EtaMessage from '../ETA';
import ShareVideoMessage from '../ShareVideo';

// other
import { isScrolledIntoView, scrollToNewMessages, scrollToBottom, useStopTouchMove } from '../../utils/helpers';

// styles
import { getApp } from '../../reducers/app';
import { initRead } from '../../actions/app';
import { setItem, getItem } from '../../utils/localStorage';
import FreeForm from '../FreeForm';
import AlarmScoring from "../AlarmScoring";

let messageMap: Record<MessageType, FC<IMessagePeace>> = {
  bot: BotMessage,
  clips: BotMessage,
  confirm: ConfirmMessage,
  verifyConfirmed: MessageAlertStatus,
  cancelConfirmed: MessageAlertStatus,
  owner: OwnerMessage,
  person: PersonMessage,
  join: JoinMessage,
  responding: RespondingMessage,
  eta: EtaMessage,
  alarm_scoring: AlarmScoring,
  freeform: FreeForm,
  ivn: ServiceMessage,
  serviceEta: PersonActionsNotifications,
  serviceShare: PersonActionsNotifications,
  serviceFreeform: PersonActionsNotifications,
  serviceLocation: PersonActionsNotifications,
  serviceDamage: PersonActionsNotifications,
  serviceSafety: PersonActionsNotifications,
  serviceCause: PersonActionsNotifications,
  video: ShareVideoMessage,
  police: PersonActionsNotifications,
  expire: ServiceMessage,
  timedOut: ServiceMessage,
  addTime: ExtendedTimeNotification,
  notice: UpdateNotice
};

const renderList = (list: IMessage[]) =>
  list.map(({ data, messageType, isFirstMessage, isLastMessage, isNew }: IMessage, i: number) => {
    const id = `${messageType}|${get(data, 'address') || get(data, 'text') || get(data, 'user.name')}|${moment(
      get(data, 'timestamp'),
    ).toISOString()}`;
    const Comp = messageMap[messageType];
    const className = cx('Wall--messageContent', { unreadMessage: isNew });
    if (Comp) {
      return (
        <Comp
          id={id}
          key={id + i}
          data={data}
          messageType={messageType}
          isFirstMessage={isFirstMessage}
          isLastMessage={isLastMessage}
          className={className}
        />
      );
    }
    return null;
  });

const Wall = () => {
  const { list, newMessagesCount } = useSelector(getChat, shallowEqual);
  const { isInitialRead } = useSelector(getApp, shallowEqual);

  const messages = renderList(list.filter(Boolean));
  const dispatch = useDispatch();

  const scrollListener = debounce(() => {
    const unreadMessage = document.querySelectorAll('.unreadMessage');
    const wallEl = document.getElementById('feed');
    const usefulData = [];
    for (let i = list.length; i > 0; i--) {
      if (isScrolledIntoView(unreadMessage[i], wallEl)) {
        const LSToken = getItem('token');
        setItem(LSToken + '|' + unreadMessage[i].id, 'true');
        usefulData.push(unreadMessage[i].id.split('|'));
      }
    }
    if (usefulData.length) {
      dispatch(read(usefulData));
    }
  }, 50);
  scrollToBottom('feed')
  useEffect(() => {
    if (!isInitialRead && list.length) {
      setTimeout(() => {
        scrollToNewMessages();
        const unreadMessage = document.querySelectorAll('.unreadMessage');
        const usefulData = [];
        for (let i = list.length; i > 0; i--) {
          const id = get(unreadMessage, '${i}.id');
          if (id) {
            usefulData.push(id.split('|'));
          }
        }
        if (usefulData.length) {
          dispatch(read(usefulData));
        }
      }, 100);
      dispatch(initRead());
    }
    scrollListener();
  }, [isInitialRead, list, dispatch, scrollListener]);

  return (
    <div id="feed" onScroll={scrollListener} className={'Wall--root'}>
      <div className={'Wall--wallOverflow'}>
        {messages}
        {/* {Boolean(newMessagesCount) && (
          <div onClick={() => scrollToBottom('feed')} className={'Wall.newMessages'}>
            <NewMessages count={newMessagesCount} />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Wall;
