import React, { Fragment } from 'react';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import TimeStamp from '../TimeStamp';
import Clip from './clip';
import ChatLogo from '../ChatLogo';
import { getIncident } from '../../reducers/incident';
import { useSelector, shallowEqual } from 'react-redux';


const CANCEL_OR_VERIFY = () => (
  <Trans i18nKey="message-bot--cancel-or-verify">
    <strong />
  </Trans>
);

const ALARM_TRIGGERED = (address: string, zone: string) => (
  <div>
    An alarm has been triggered at <strong> {address} </strong> from <strong> {zone} </strong>
  </div>
);
const CLIPS = () => <div>New clips have been received</div>;

type BotMessageType = 'verify' | 'triggered' | 'clips';

interface IProps {
  isBotNameHidden?: boolean;
  isLogoHidden?: boolean;
  address?: string;
  zone?: string;
  clips?: IClipAPI[];
  type: BotMessageType;
}

let messages: Record<BotMessageType, any> = {
  verify: CANCEL_OR_VERIFY,
  triggered: ALARM_TRIGGERED,
  clips: CLIPS,
};

export const Bot = ({ isLogoHidden, isBotNameHidden, type, address, zone }: IProps) => {
  const [t] = useTranslation();

  const timeline = (
    <>
      <div className={'MessageBot--span'}></div>
      <div className={cx('MessageBot--time', 'text-grey2')}>{t('message-bot--name')}</div>
    </>
  );

  return (
    <div className={cx('MessageBot--root')}>
      <div className={'MessageBot--timeline'}>{!isBotNameHidden && timeline}</div>
      <div className={'MessageBot--messageline'}>
        <ChatLogo isIconHidden={isLogoHidden} />
        <div className={'MessageBot--content'}>
          <div className={'MessageBot--message'}>{messages[type](address, zone)}</div>
        </div>
      </div>
    </div>
  );
};

const MessageBot = ({ data, id, className }: IMessagePeace) => {
  const { address, zone, timestamp, clips } = data as IBotMessageData;
  const { clipsOnly } = data as IClipsMessageData;
  let { cs_release_at, created_at } = useSelector(getIncident, shallowEqual);
  let date_cs_release_at = new Date(cs_release_at);
  let date_created_at = new Date(created_at);

  let govUser = date_cs_release_at.getUTCFullYear() === date_created_at.getUTCFullYear() &&
      date_cs_release_at.getUTCMonth() === date_created_at.getUTCMonth() &&
      date_cs_release_at.getUTCDate() === date_created_at.getUTCDate() &&
      date_cs_release_at.getUTCHours() === date_created_at.getUTCHours() &&
      date_cs_release_at.getUTCMinutes() === date_created_at.getUTCMinutes();

  return (
    <Fragment>
      {clips &&
        clips.length > 0 &&
        clips.map((clip, i) => (
          <div key={clip.id} className={className}>
            <Clip clip={clip} isLogoHidden />
          </div>
        ))}
      {address && !govUser && (
      <div className={className}>
        <Bot type="triggered" address={address} zone={zone} isLogoHidden />
      </div>
      )}
      {govUser && (
        <div className={className}>
          <Bot type="triggered" address={address} zone={zone} />
        </div>
      )}
      {clipsOnly && (
        <div className={className}>
          <Bot isBotNameHidden type="clips" />
        </div>
      )}
      {!clipsOnly && !govUser && (
        <div className={className}>
          <Bot isBotNameHidden type="verify" />
        </div>
      )}
      {!clipsOnly && (
        <div id={id} className={className}>
          <TimeStamp timestamp={timestamp.toString()} />
        </div>
      )}
    </Fragment>
  );
};

export default MessageBot;
