import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import auth from './app';
import input from './input';
import confirm from './confirm';
import featureFlags from './featureFlags';
// inject import
import incident from './incident';
import chat from './chat';

export default (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    auth,
    // inject usage
    incident,
    confirm,
    chat,
    input,
    featureFlags,
  });
