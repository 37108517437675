import { all } from 'redux-saga/effects';

import auth from './auth';
// inject import
import incident from './incident';
import timer from './timer';
import chat from './chat';
import input from './input';
import alarm from './alarm';
import network from './network';
import featureFlags from './featureFlags';

const allSagas = [
  auth,
  // inject usage
  incident,
  alarm,
  timer,
  input,
  chat,
  network,
  featureFlags,
];

export default function* appSagas() {
  yield all(allSagas.map(f => f()));
}
