import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { getIncident, IIncident } from '../../reducers/incident';
import { shallowEqual, useSelector } from 'react-redux';
import { getChat, IChat } from '../../reducers/chat';

export const ADD_SECONDS = 'time-added';
export const BY_USER = 'added-by';

interface IProps {
  isTimestampHidden?: boolean;
  user: IUser;
}

export const ExtendedTimeNotification = ({ user }: IProps) => {
  const incident: IIncident = useSelector(getIncident, shallowEqual);
  const { t } = useTranslation();
  const { owner }: IChat = useSelector(getChat, shallowEqual);

  const timeline = (
    <>
      <div className={cx('TimeExtended--time')}>
        <div className={'TimeExtended--clock'}>⏱</div>
        <div className={'TimeExtended--message'}>
          <span> {t(ADD_SECONDS, { seconds: incident.time_to_extend / 1000 })}</span>
          {t(BY_USER)}
          {user.token === owner.token ? 'You' : user.name}
        </div>
      </div>
    </>
  );

  return (
    <div className={cx('TimeExtended--root')}>
      <div className={'TimeExtended--timeline'}>{timeline}</div>
    </div>
  );
};

const MessageOwner = ({ data, id, className }: IMessagePeace) => {
  const { user } = data as IUserMessageData;
  return (
    <div id={id} className={className}>
      <ExtendedTimeNotification user={user} />
    </div>
  );
};

export default MessageOwner;
