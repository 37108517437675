/* eslint-disable @typescript-eslint/camelcase */
import get from 'lodash/get';
import reject from 'lodash/reject';
import findIndex from 'lodash/findIndex';
import { Reducer, IAction, IState as ISt, IState } from './redux-create-reducer';
import moment from 'moment';
import { getItem } from './localStorage';
import { sortByDates } from './helpers';

export const SERVICE_MSG_SHARE = '$$##$$_-_SHARE_-_$$##$$';
export const SERVICE_MSG_ETA = '$$##$$_-_ETA_-_$$##$$';
export const SERVICE_FREEFORM_SHARE = '$$##$$_-_FREEFORM_-_$$##$$';
export const SERVICE_MSG_LOCATION = '$$##$$_-_LOCATION_-_$$##$$';
export const SERVICE_MSG_DAMAGE = '$$##$$_-_DAMAGE_-_$$##$$';
export const SERVICE_MSG_SAFETY = '$$##$$_-_SAFETY_-_$$##$$';
export const SERVICE_MSG_CAUSE = '$$##$$_-_CAUSE_-_$$##$$';


const serviceTypes = {
  [SERVICE_MSG_SHARE]: 'serviceShare',
  [SERVICE_MSG_ETA]: 'serviceEta',
  [SERVICE_FREEFORM_SHARE]: 'serviceFreeform',
  [SERVICE_MSG_LOCATION]: 'serviceLocation',
  [SERVICE_MSG_DAMAGE]: 'serviceDamage',
  [SERVICE_MSG_SAFETY]: 'serviceSafety',
  [SERVICE_MSG_CAUSE]: 'serviceCause',
};

const TIMED_OUT_MESSAGE = 'The Incident has timed out.';
const TIMER_OUT = 'timed_out';

export function sortList(list: IMessage[], reverse?: boolean) {
  return [...list].sort((ma: IMessage, mb: IMessage) => {
    return sortByDates(ma.data.timestamp, mb.data.timestamp, reverse);
  });
}

export function comparator(m: IMessage) {
  return ([type, uniq]: string[]) => {
    if (type === m.messageType) {
      if (m.messageType === 'bot') {
        return uniq == get(m, 'data.address');
      }
      if (['owner', 'person', 'ivn'].includes(m.messageType)) {
        return uniq == get(m, 'data.text');
      }

      if (m.messageType === 'join') {
        return uniq == get(m, 'data.user.name');
      }
    }
    return false;
  };
}
export function getClip(incident: IIncidentAPI) {
  const poster =
    incident.clips instanceof Array
      ? get(
          incident.clips.find((c: IClipAPI) => typeof c.image === 'string' && c.image.length > 3),
          'image',
        ) || ''
      : '';

  const liveview = (incident.liveviews || []).find((c: ILiveViewAPI) => c.url_mjpg || c.url_flv || c.url_mjpg_proxy);
  const stream = get(liveview, 'url_mjpg') || get(liveview, 'url_flv') || get(liveview, 'url_mjpg_proxy');
  const video =
    incident.clips instanceof Array
      ? get(
          incident.clips.find((c: IClipAPI) => typeof c.video === 'string' && c.video.length > 3),
          'video',
        ) || ''
      : '';

  return {
    video: video || stream,
    poster,
  };
}

export function makeServiceMessage(messageType: MessageType, timestamp: string | Date, user: IUser): IMessage {
  return {
    messageType,
    isFirstMessage: false,
    isLastMessage: false,
    data: {
      timestamp,
      user,
    },
  };
}

export interface IPNUserMessage {
  body: string;
  created_at: string | Date;
  timestamp: string | Date;
  isOwner: boolean;
  sender: IUser;
}

export interface IPNJoin {
  timestamp: string | Date;
  sender: IUser;
  created_at: string | Date;
}

/////////////////////////////////////////////////////
/////////////////////   TODO   //////////////////////
/////////////////////////////////////////////////////
////   TRY TO PREVENT INSIDE SAGA BEFORE ACTION   ///
/////////////////////////////////////////////////////
export function preventDuplication(fn: Reducer, msgHandler: (...arg: any[]) => IMessage): Reducer {
  return (newMessage, state, type) => {
    const msg = msgHandler(newMessage, state);
    const hasDupliction = state.list.some(
      (m: IMessage) =>
        m.data.timestamp === get(msg, 'data.timestamp') && get(m, 'data.user.token') === get(msg, 'user.token'),
    );

    if (hasDupliction) {
      return {};
    }
    return { list: fn(newMessage, state, type) };
  };
}

export function markSameUserMessages(newMessage: IMessage, list: IMessage[]) {
  const messages = [...sortList(list)];
  let lastServiceMessage;
  let lastMessage;
  let i = list.length - 1;
  while (i) {
    const msg = messages[i--];

    if (['confirm', 'eta', 'video', 'responding'].includes(msg.messageType)) {
      lastServiceMessage = msg;
      messages.pop();
      continue;
    } else {
      lastMessage = msg;
      messages.pop();
      break;
    }
  }

  const newToken = get(newMessage, 'data.user.token');
  const lastToken = get(lastMessage, 'data.user.token');

  const theSameUser = lastMessage && lastMessage.messageType === newMessage.messageType && newToken === lastToken;
  if (theSameUser && lastMessage) {
    lastMessage.isLastMessage = false;
  }

  return [
    ...messages,
    ...((lastMessage && [lastMessage]) || []),
    { ...newMessage, isLastMessage: true, isFirstMessage: !theSameUser },
    ...(lastServiceMessage ? [lastServiceMessage] : []),
  ];
}

export function convertPubNubMessage(
  { sender, body, created_at, timestamp }: IPNUserMessage,
  state: Record<string, any>,
): IMessage {
  const ownerToken = get(state, 'owner.token');
  const senderToken = get(sender, 'token');
  const [maybeType] = (body || '').split('|');
  const serviceType: MessageType | undefined = serviceTypes[maybeType];
  const maybeIVN: MessageType | undefined =
    get(sender, 'name') === 'IVN' || [TIMED_OUT_MESSAGE, TIMER_OUT].includes(body) ? 'ivn' : undefined;
  let isNew = !serviceType && !maybeIVN && ownerToken !== senderToken;
  const personType = !isNew ? 'owner' : 'person';
  const messageType = serviceType || maybeIVN || personType;

  const tsmp = created_at || timestamp;

  const isExist = getItem([ownerToken, messageType, body, moment(tsmp).toISOString()].join('|'));

  if (isExist) {
    isNew = false;
  }

  return {
    messageType,
    isNew,
    data: {
      user: sender,
      text: body,
      timestamp: tsmp,
    },
  };
}

export function convertPubNubJoin({ sender, timestamp, created_at }: IPNJoin): IMessage {
  return {
    messageType: 'join',
    data: {
      user: sender,
      timestamp: timestamp || created_at,
    },
  };
}

export function lookToMessage(dm: string[][]) {
  return (m: IMessage) => (m.isNew && dm.some(comparator(m)) ? { ...m, isNew: false } : m);
}

export function replaceMessageItemByCriteria(list: IMessage[], criteria: [string, any], newMsg: any) {
  const index = findIndex(list, criteria);
  if (index !== -1) {
    list[index] = newMsg;
    return list;
  }
  return [...list, newMsg];
}

export function middleware(funcArray: Reducer[]) {
  return (action: IAction, state: ISt, type: string) =>
    funcArray.reduce((result, func: Reducer) => ({ ...result, ...func(action, result, type) }), state);
}

export function replaceChatItem(destType: MessageType, srcType: MessageType): Reducer {
  return (newMessage, state) => {
    const msg = convertPubNubMessage(newMessage, state);

    const sender = get(msg, 'data.user') || state.owner;
    const message = makeServiceMessage(srcType, msg.data.timestamp, sender);

    if (['confirm', 'eta', 'video', 'responding'].includes(destType)) {
      message.data.timestamp = moment()
        .add(1, 'hour')
        .toISOString();
    }
    return { list: replaceMessageItemByCriteria(state.list, ['messageType', destType], message) };
  };
}

export function addNewChatItem(type: MessageType): Reducer {
  console.log("Add new chat item")
  console.log(type)
  const calledIdNotDuplicaton: Reducer = (newMessage, state) => {
    const msg = convertPubNubMessage(newMessage, state);
    const sender = get(msg, 'data.user') || state.owner;

    console.log(newMessage, msg);

    if (['confirm', 'eta', 'video', 'responding'].includes(type)) {
      msg.data.timestamp = moment()
        .add(1, 'hour')
        .toISOString();
    }
    return [...state.list, makeServiceMessage(type, msg.data.timestamp, sender)];
  };
  return preventDuplication(calledIdNotDuplicaton, (payload: IMessage, { owner }) =>
    makeServiceMessage(type, get(payload, 'data.timestamp') || new Date(), owner),
  );
}

export function removeChatItem(...types: MessageType[]): Reducer {
  return (_, { list }): IState => ({
    list: reject(list, (el: IMessage) => types.includes(el.messageType)),
  });
}
