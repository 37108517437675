import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setMedia } from '../../actions/app';

interface MediaProps {
  clip: IClipAPI;
}

const Media = ({ clip }: MediaProps) => {
  const dispatch = useDispatch();
  const video = useRef(document.createElement('video'));

  const [size, setSize] = useState([100, 100]);

  const isAudio = /audio/.test(clip.media_type);

  const exit = () => {
    dispatch(setMedia(null));
  };

  const load = () => {
    /* CALCULATE WINTH AND HEIGHT OF VIDEO FOR ANDROID */
    const { innerHeight, innerWidth } = window;
    const isDesktop = innerWidth > 1024;

    const isLandscape = innerWidth > innerHeight;
    const koef = isLandscape ? innerHeight / innerWidth : innerWidth / innerHeight;
    const imgWidth = isLandscape ? innerHeight : innerWidth;

    const imgHeight = imgWidth * koef;
    let totalWidth = isLandscape ? imgWidth * 1.3 : imgWidth;
    let totalHeight = isLandscape ? 1.3 * imgHeight : imgHeight;
    if (isDesktop) {
      totalWidth = 750;
      totalHeight = totalWidth * koef;
    }

    setSize([totalWidth, totalHeight]);
  };

  useEffect(() => {
    const changeImage = () => {
      load();
    };

    window.addEventListener('resize', changeImage);

    return () => window.removeEventListener('resize', changeImage);
  });

  useEffect(() => {
    if (video.current) {
      video.current.onplay = () => {
        /* AUTO FULLSCREEN FOR ANDROID */
        // if (video.current.requestFullscreen) {
        //   video.current.requestFullscreen();
        // }
        load();
      };
      video.current.onended = exit;
      video.current.addEventListener('webkitendfullscreen', exit, false);
    }
    return () => {
      video.current && video.current.removeEventListener('webkitendfullscreen', exit, false);
    };
  }, []);

  const player = isAudio ? (
    <audio onLoad={load} autoPlay className={'Media--clip'} controls src={clip.media_url}>
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  ) : (
    <video
      style={{ width: size[0], height: size[1] }}
      ref={video}
      autoPlay
      className={'Media--clip'}
      src={clip.media_url || clip.video}
      controls
    >
      Your browser does not support the
      <code>video</code> element.
    </video>
  );
  return (
    <div className={'Media--root'}>
      <div onClick={exit} className={'Media--close'} />
      <div className={'Media--player'}>
        <figure>
          <figcaption>{clip.name}</figcaption>
          {player}
        </figure>
      </div>
      <div className={'Media--background'} />
    </div>
  );
};

export default Media;
