import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const cacheBust = Date.now();

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',

    debug: false,
    whitelist: ['en', 'es'],
    backend: {
      loadPath: '/locales/{{lng}}.json?_=' + cacheBust,
    },

    react: {
      wait: true,
    },

    detection: {
      caches: ['blnLngDetector', 'localStorage'],
      order: ['blnLngDetector', 'localStorage', 'navigator'],
    },
  });

export default i18n;
