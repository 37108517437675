import React from 'react';
import { Trans } from 'react-i18next';

interface IProps {
  name: string;
}

export const Join = ({ name }: IProps) => {
  return (
    <div className="MessageJoin--root">
      <div>
        <strong>+ {name} </strong> has joined
      </div>
    </div>
  );
};

const MessageJoin = ({ data, id, className }: IMessagePeace) => {
  const { user } = data as IJoinMessageData;
  return (
    <div id={id} className={className}>
      <Join name={user.name} />
    </div>
  );
};

export default MessageJoin;
