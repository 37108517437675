import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row, Input } from 'reactstrap';
import cx from 'classnames';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import EyeMessage from '../../components/MessageEye';
import { setFreeFormData } from '../../actions/confirm';
import ChatLogo from '../../components/ChatLogo';

export const FREE_FORM_TEXT = 'free-form-text';
export const FREE_FORM_PLACEHOLDER = 'free-form-placeholder';

const FreeForm = () => {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const dispatch = useDispatch();

  const updateText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = get(e, 'target.value');
    if (typeof val === 'string' && val.length <= 200) {
      setText(e.target.value);
    }
  };
  const send = (e: React.SyntheticEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(setFreeFormData(text));
  };
  return (
    <>
      <div className={cx('FreeForm--root')}>
        <ChatLogo />
        <Container className={cx('FreeForm--confirmMessage')} expand="lg">
          <Row className={cx('FreeForm--confirmHeader')}>
            <Col>{t(FREE_FORM_TEXT)}</Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Input onChange={updateText} type="text" value={text} placeholder={t(FREE_FORM_PLACEHOLDER)} />
            </Col>
            <Col xs={12}>
              <Button className={'FreeForm--b'} onClick={send} color="light" block>
                <strong>{t('send')}</strong>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={'FreeForm--eyeMessage'}>
        <EyeMessage />
      </div>
    </>
  );
};

export default FreeForm;
