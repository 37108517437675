import { createEmptyAction } from '../utils/actions';

export interface ITimerData {
  time: number;
}

export const ADD_TIME = 'TIMER/ADD_TIME';
export const PING = 'TIMER/PING';

export const types = {
  ADD_TIME,
  PING,
};

export const addTime = createEmptyAction(ADD_TIME);
export const ping = createEmptyAction(PING);

export default {
  addTime,
  ping,
};
