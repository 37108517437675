/* eslint-disable @typescript-eslint/camelcase */
import createReducer from '../utils/redux-create-reducer';
import {
  READ,
  EVENT_MESSAGE,
  EVENT_JOINED,
  SET_OWNER,
  SHOW_NOTIFICATION_EXPIRE,
  EVENT_ADD_TIME_SUCCESS,
  INCIDENT_UPDATE,
  TIME_EXPIRED,
  UPDATE_NOTICE,
} from '../actions/chat';
import {
  REQUEST_ACTION,
  SHOW_RESPONDING,
  CLOSE_RESPONDING,
  SHOW_ETA,
  CLOSE_ETA,
  SHOW_ALARM_SCORING,
  CLOSE_ALARM_SCORING,
  SHOW_SHARE_VIDEO,
  CLOSE_SHARE_VIDEO,
  DENY_ACTION,
  CONFIRM_VERIFY_ACTION,
  CONFIRM_CANCEL_ACTION,
  EVENT_CALL_POLICE,
  EVENT_DISARM_ALARM,
  SHOW_FREEFORM,
  CLOSE_FREEFORM,
  CALL_POLICE,
  DISARM,
} from '../actions/confirm';
import {
  removeChatItem,
  markSameUserMessages,
  convertPubNubMessage,
  IPNUserMessage,
  lookToMessage,
  replaceChatItem,
  middleware,
  addNewChatItem,
  IPNJoin,
  convertPubNubJoin,
  preventDuplication,
  sortList,
} from '../utils/chatFeedHelpers';
import filter from 'lodash/filter';
import { FETCH_INCIDENT_SUCCESS } from '../actions/incident';
import { get } from 'lodash';
import { sortByDates } from '../utils/helpers';
import { REACT_APP_CLIPS_LIMIT } from '../env';

const INTERUPTION_EVENTS = ['timed_out', 'called_police', 'disarmed_alarm', 'auto_close', 'adt_has_notified_police'];
const ALARM_STATES: MessageType[] = ['confirm', 'eta', 'video', 'responding', 'freeform', 'alarm_scoring'];

export interface IChat {
  list: IMessage[];
  newMessagesCount: number;
  owner: IUser;
}

interface IState {
  chat: IChat;
}

export const initialState: IChat = {
  list: [],
  newMessagesCount: 0,
  owner: {
    token: '',
    name: '',
  },
};

export const getChat = (state: IState): IChat => state.chat;

const clipsLimit = isNaN(Number(REACT_APP_CLIPS_LIMIT)) ? 1000 : Number(REACT_APP_CLIPS_LIMIT);

export default createReducer(initialState, {
  reset: () => ({ list: [] }),
  [FETCH_INCIDENT_SUCCESS]: (inc, { list }) => ({
    list: [
      {
        messageType: 'bot',
        data: {
          address: inc.site.address,
          timestamp: inc.created_at,
          clips: inc.clips
            .sort((ca: IClipAPI, cb: IClipAPI) => sortByDates(cb.created_at, ca.created_at))
            .filter((_: IClipAPI, i: number) => i < clipsLimit),
          zone: inc.zones && get(inc.zone_started_sigchat, 'description'),
        },
      },
    ],
  }),
  [SET_OWNER]: (owner: IUser) => ({ owner }),
  [EVENT_MESSAGE]: preventDuplication(
    (newMessage: IPNUserMessage, state) => markSameUserMessages(convertPubNubMessage(newMessage, state), state.list),
    convertPubNubMessage,
  ),
  [EVENT_JOINED]: preventDuplication(
    (newMessage: IPNJoin, { list }) => [...list, convertPubNubJoin(newMessage)],
    convertPubNubJoin,
  ),
  [READ]: (detectedMessages: string[][], { list }) => ({
    list: list.map(lookToMessage(detectedMessages)),
  }),
  [REQUEST_ACTION]: replaceChatItem('confirm', 'confirm'),
  [DENY_ACTION]: removeChatItem('confirm'),
  //Sends pubnub to Cancels the alarm
  [CONFIRM_CANCEL_ACTION]: replaceChatItem('confirm', 'cancelConfirmed'),
  //   export const CALL_POLICE = 'ALARM/CALL_POLICE';
  // export const DISARM = 'ALARM/DISARM';
  [CALL_POLICE]: replaceChatItem('verifyConfirmed', 'verifyConfirmed'),
  [DISARM]: replaceChatItem('cancelConfirmed', 'cancelConfirmed'),
  // [EVENT_CALL_POLICE]: removeChatItem('confirm'),
  // [EVENT_DISARM_ALARM]: removeChatItem('confirm'),
  //[EVENT_CALL_POLICE]: replaceChatItem('verifyConfirmed', 'verifyConfirmed'),
  [EVENT_DISARM_ALARM]: middleware([removeChatItem(...ALARM_STATES), removeChatItem('verifyConfirmed'), removeChatItem('cancelConfirmed'), addNewChatItem('cancelConfirmed')]),
  [EVENT_CALL_POLICE]: middleware([removeChatItem(...ALARM_STATES), removeChatItem('cancelConfirmed'), removeChatItem('verifyConfirmed'), addNewChatItem('verifyConfirmed')]),
    //Sends pubnub to call the police
  [CONFIRM_VERIFY_ACTION]: removeChatItem('confirm'),
  [SHOW_RESPONDING]: addNewChatItem('responding'),
  [CLOSE_RESPONDING]: removeChatItem('responding'),
  [SHOW_ETA]: replaceChatItem('responding', 'eta'),
  [CLOSE_ETA]: removeChatItem('eta'),
  [SHOW_ALARM_SCORING]: replaceChatItem('eta', 'alarm_scoring'),
  [CLOSE_ALARM_SCORING]: removeChatItem('alarm_scoring'),
  [SHOW_SHARE_VIDEO]: replaceChatItem('eta', 'video'),
  [CLOSE_SHARE_VIDEO]: removeChatItem('video'),
  [SHOW_FREEFORM]: replaceChatItem('responding', 'freeform'),
  [CLOSE_FREEFORM]: removeChatItem('freeform'),
  [SHOW_NOTIFICATION_EXPIRE]: addNewChatItem('expire'),
  [EVENT_ADD_TIME_SUCCESS]: addNewChatItem('addTime'),
  [TIME_EXPIRED]: replaceChatItem('timedOut', 'timedOut'),
  [UPDATE_NOTICE]: middleware([addNewChatItem('notice'), addNewChatItem('cancelConfirmed')]),
  [INCIDENT_UPDATE]: (payload: any, state, type: string) => {
    let result = {};
    console.log("Payload Status");
    console.log(payload.status);
    console.log("Interruption event?");
    console.log(INTERUPTION_EVENTS.includes(payload.status));
    if (INTERUPTION_EVENTS.includes(payload.status)) {
      result = removeChatItem(...ALARM_STATES)(payload, state, type);
    }

    if (payload.status === 'timed_out') {
      result = replaceChatItem('timedOut', 'timedOut')(payload, { ...state, ...result }, type);
    }

    if ((payload.clips || []).length > 0) {
      return {
        list: [
          ...state.list,
          {
            messageType: 'clips',
            data: {
              clipsOnly: true,
              timestamp: new Date(),
              clips: payload.clips
                .sort((ca: IClipAPI, cb: IClipAPI) => sortByDates(cb.created_at, ca.created_at))
                .filter((_: IClipAPI, i: number) => i < clipsLimit),
            },
          },
        ],
      };
    }
    return result;
  },
  ['*']: (_, { list }) => ({
    list: sortList(list, true),
    newMessagesCount: filter(list).length,
  }),
});
