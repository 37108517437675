import { createAction } from '../utils/actions';

export const READ = 'CHAT/READ';
export const SET_OWNER = 'CHAT/SET_OWNER';
export const SHOW_NOTIFICATION_EXPIRE = 'CHAT/SHOW_NOTIFICATION_EXPIRE';
export const EVENT_ADD_TIME_SUCCESS = '@PUBNUB/EVENT_ADD_TIME_SUCCESS';
export const UPDATE_NOTICE = '@PUBNUB/UPDATE_NOTICE';
export const EVENT_MESSAGE = '@PUBNUB/EVENT_MESSAGE';
export const DEFAULT_MESSAGE = '@PUBNUB/DEFAULT_MESSAGE';
export const EVENT_JOINED = '@PUBNUB/EVENT_JOINED';
export const INCIDENT_UPDATE = '@PUBNUB/INCIDENT_UPDATE';
export const TIME_EXPIRED = '@PUBNUB/TIME_EXPIRED';
export const SET_AGENT_OPENED = '@PUBNUB/SET_AGENT_OPENED';
export const EVENT_CALL_POLICE = '@PUBNUB/EVENT_CALL_POLICE';
export const EVENT_DISARM_ALARM = '@PUBNUB/EVENT_DISARM_ALARM';
export const SIGNAL_TRANSMISSION_FAILED = '@PUBNUB/SIGNAL_TRANSMISSION_FAILED';


export const types = {
  READ,
  EVENT_JOINED,
  EVENT_ADD_TIME_SUCCESS,
  UPDATE_NOTICE,
  EVENT_MESSAGE,
  DEFAULT_MESSAGE,
  SET_OWNER,
  INCIDENT_UPDATE,
  TIME_EXPIRED,
  SET_AGENT_OPENED,
  EVENT_CALL_POLICE,
  EVENT_DISARM_ALARM,
  SIGNAL_TRANSMISSION_FAILED
};

export const read = createAction(READ);
export const setOwner = createAction(SET_OWNER);
export const showNotificationExpire = createAction(SHOW_NOTIFICATION_EXPIRE);

export default {
  read,
  setOwner,
  showNotificationExpire,
};
