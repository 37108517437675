import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import EyeMessage from '../../components/MessageEye';
import { responding } from '../../actions/confirm';
import ChatLogo from '../../components/ChatLogo';

export const CONFIRM_YES = 'alarmConfirmation--yes';
export const CONFIRM_NO = 'alarmConfirmation--no';
export const RESPONDING_QUESTION = 'responding--question';

const RespondingMessage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const respond = (isResponding: boolean) => (e: React.SyntheticEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(responding(isResponding));
  };

  return (
    <>
      <div className={cx('Responding--root')}>
        <ChatLogo />
        <Container className={cx('Responding--confirmMessage')} expand="lg">
          <Row className={cx('Responding--confirmHeader')}>
            <Col>{t(RESPONDING_QUESTION)}</Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Button className={'Responding--b'} onClick={respond(false)} color="light" block>
                <span>{t(CONFIRM_NO)}</span>
              </Button>
            </Col>
            <Col xs={6}>
              <Button className={'Responding--b'} onClick={respond(true)} color="light" block>
                <span>{t(CONFIRM_YES)}</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={'Responding--eyeMessage'}>
        <EyeMessage />
      </div>
    </>
  );
};

export default RespondingMessage;
