import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import cx from 'classnames';
import { addTime, ping } from '../../actions/timer';
import { getIncident, IIncident } from '../../reducers/incident';
import { convertSecondsToTime, getExpirationSeconds, useStopTouchMove } from '../../utils/helpers';
import { Button } from 'reactstrap';
import { REACT_APP_TIME_TO_EXDEND_TIMER } from '../../env';

export const REMAINING = 'timer--remaining-to-respond';
export const TAP = 'timer--tap-to-add-time';
export const ADD_X_SEC = 'add-time-button-value';

const Timer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const incident: IIncident = useSelector(getIncident, shallowEqual);
  const [currentTime, setCurrentTime] = useState(getExpirationSeconds(incident['cs_release_at']));
  const isLittleTimeLeft = currentTime < Number(REACT_APP_TIME_TO_EXDEND_TIMER || 60);

  const addMoreTime = () => {
    if (isLittleTimeLeft) {
      dispatch(addTime());
    }
  };
  const touchmoveRef = useStopTouchMove();

  const content = (
    <div className={'Timer--counter'}>
      <div className={'Timer--message'}>
        <span>{convertSecondsToTime(currentTime)}</span> {t(REMAINING)}
      </div>
      {isLittleTimeLeft && !incident['time_extended'] && incident.time_to_extend > 0 && (
        <Button onClick={addMoreTime} variant="outline-light" className={cx('Timer--addTimeButton')}>
          {t(ADD_X_SEC, { seconds: incident.time_to_extend / 1000 })}
        </Button>
      )}
    </div>
  );

  useEffect(() => {
    if (currentTime > 0) {
      setTimeout(() => {
        setCurrentTime(getExpirationSeconds(incident['cs_release_at']));
      }, 1000);
    } else {
      dispatch(ping());
    }
    setCurrentTime(getExpirationSeconds(incident['cs_release_at']));
  }, [incident, currentTime, dispatch]);

  return currentTime > 0 ? (
    <div
      ref={touchmoveRef}
      className={cx('text-addTime text-center text-white', {
        ['Timer--addTime']: isLittleTimeLeft && !incident['time_extended'],
      })}
    >
      {content}
    </div>
  ) : (
    <></>
  );
};

export default Timer;
