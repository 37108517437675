export const {
  REACT_APP_ENV,
  REACT_APP_API_BASE_URL,
  NODE_ENV,
  PUBLIC_URL,
  REACT_APP_LINKS__CALL_ADT,
  REACT_APP_LINKS__TERMS_OF_SERVICE,
  REACT_APP_LINKS__FAQ,
  REACT_APP_LINKS__PRIVACY_POLICY,
  REACT_APP_LINKS__MYADT,
  REACT_APP_BLOCK_SHARE_DATA_PROMPT,
  REACT_APP_CLIPS_LIMIT,
  REACT_APP_TIME_TO_EXDEND_TIMER,

  REACT_APP_COMMERCIAL_LINKS__CALL_ADT,
  REACT_APP_COMMERCIAL_LINKS__TERMS_OF_SERVICE,
  REACT_APP_COMMERCIAL_LINKS__FAQ,
  REACT_APP_COMMERCIAL_LINKS__PRIVACY_POLICY,
  REACT_APP_COMMERCIAL_LINKS__MYADT,
  REACT_APP_COMMERCIAL_BLOCK_SHARE_DATA_PROMPT,
  REACT_APP_COMMERCIAL_CLIPS_LIMIT,
  REACT_APP_COMMERCIAL_TIME_TO_EXDEND_TIMER,

  REACT_APP_RESICOM_LINKS__CALL_ADT,
  REACT_APP_RESICOM_LINKS__TERMS_OF_SERVICE,
  REACT_APP_RESICOM_LINKS__FAQ,
  REACT_APP_RESICOM_LINKS__PRIVACY_POLICY,
  REACT_APP_RESICOM_LINKS__MYADT,
  REACT_APP_RESICOM_BLOCK_SHARE_DATA_PROMPT,
  REACT_APP_RESICOM_CLIPS_LIMIT,
  REACT_APP_RESICOM_TIME_TO_EXDEND_TIMER,
} = process.env;
export const API_BASE_URL = REACT_APP_API_BASE_URL;

export const featureFlags = {};
