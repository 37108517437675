import React from 'react';
import { Container } from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
interface IProps {
  timestamp: string;
}

const TimeStamp = ({ timestamp }: IProps) => {
  const [t] = useTranslation();
  return (
    <Container className={'TimeStamp--root'}>
      {t('today')} <strong>{moment(timestamp).format('hh:mm A')}</strong>
    </Container>
  );
};

export default TimeStamp;
