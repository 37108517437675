import { createEmptyAction, createAction } from '../utils/actions';

export const REQUEST_ACTION = 'ALARM/REQUEST';
export const CONFIRM_CANCEL_ACTION = 'ALARM/CONFIRM_CANCEL';
export const CONFIRM_VERIFY_ACTION = 'ALARM/CONFIRM_VERIFY';
export const DENY_ACTION = 'ALARM/DENY';
export const RESPONDING_ACTION = 'ALARM/RESPONDING';
export const ETA_ACTION = 'ALARM/ETA';
export const ALARM_SCORING = 'ALARM/ALARM_SCORING';
export const SELECTED_QUESTIONS = 'ALARM/SELECTED_QUESTIONS';
export const SHARE_VIDEO_ACTION = 'ALARM/SHARE_VIDEO';
export const SHOW_RESPONDING = 'ALARM/SHOW_RESPONDING';
export const CLOSE_RESPONDING = 'ALARM/CLOSE_RESPONDING';
export const SHOW_FREEFORM = 'ALARM/SHOW_FREEFORM';
export const FREEFORM = 'ALARM/FREEFORM';
export const CLOSE_FREEFORM = 'ALARM/CLOSE_FREEFORM';
export const SHOW_ETA = 'ALARM/SHOW_ETA';
export const CLOSE_ETA = 'ALARM/CLOSE_ETA';
export const CLOSE_ALARM_SCORING = 'ALARM/CLOSE_ALARM_SCORING';
export const SHOW_ALARM_SCORING = 'ALARM/SHOW_ALARM_SCORING';
export const SHOW_SHARE_VIDEO = 'ALARM/SHOW_SHARE_VIDEO';
export const CLOSE_SHARE_VIDEO = 'ALARM/CLOSE_SHARE_VIDEO';
export const CALL_POLICE = 'ALARM/CALL_POLICE';
export const DISARM = 'ALARM/DISARM';
export const EVENT_CALL_POLICE = '@PUBNUB/EVENT_CALL_POLICE';
export const EVENT_DISARM_ALARM = '@PUBNUB/EVENT_DISARM_ALARM';

export const types = {
  REQUEST_ACTION,
  CONFIRM_CANCEL_ACTION,
  CONFIRM_VERIFY_ACTION,
  DENY_ACTION,
  EVENT_DISARM_ALARM,
  SHOW_RESPONDING,
  CLOSE_RESPONDING,
  EVENT_CALL_POLICE,
  SHOW_ETA,
  SHOW_ALARM_SCORING,
  CLOSE_ALARM_SCORING,
  SHOW_SHARE_VIDEO,
  CLOSE_SHARE_VIDEO,
  CALL_POLICE,
  RESPONDING_ACTION,
  ETA_ACTION,
  ALARM_SCORING,
  SELECTED_QUESTIONS,
  SHARE_VIDEO_ACTION,
  SHOW_FREEFORM,
  CLOSE_FREEFORM,
  FREEFORM,
};

export const requestAlarm = createAction(REQUEST_ACTION);
export const confirmCancelAlarm = createEmptyAction(CONFIRM_CANCEL_ACTION);
export const confirmVerifyAlarm = createEmptyAction(CONFIRM_VERIFY_ACTION);
export const denyAction = createEmptyAction(DENY_ACTION);
export const showResponging = createEmptyAction(SHOW_RESPONDING);
export const closeResponging = createEmptyAction(CLOSE_RESPONDING);
export const showETA = createEmptyAction(SHOW_ETA);
export const closeETA = createEmptyAction(CLOSE_ETA);
export const showAlarmScoring = createEmptyAction(SHOW_ALARM_SCORING);
export const closeAlarmScoring = createEmptyAction(CLOSE_ALARM_SCORING);
export const showShareVideo = createEmptyAction(SHOW_SHARE_VIDEO);
export const showFreeForm = createEmptyAction(SHOW_FREEFORM);
export const closeFreeForm = createEmptyAction(CLOSE_FREEFORM);
export const setFreeFormData = createAction(FREEFORM);
export const closeShareVideo = createEmptyAction(CLOSE_SHARE_VIDEO);
export const callPolice = createEmptyAction(CALL_POLICE);
export const disarm = createEmptyAction(DISARM);
export const responding = createAction(RESPONDING_ACTION);
export const estimatedTimeArrival = createAction(ETA_ACTION);
export const alarmScoring = createAction(ALARM_SCORING);
export const selectedQuestions = createAction(SELECTED_QUESTIONS);
export const shareVideo = createAction(SHARE_VIDEO_ACTION);

export default {
  requestAlarm,
  confirmCancelAlarm,
  confirmVerifyAlarm,
  denyAction,
  responding,
  estimatedTimeArrival,
  alarmScoring,
  selectedQuestions,
  shareVideo,
  callPolice,
  disarm,
  showResponging,
  closeResponging,
  showETA,
  closeETA,
  showAlarmScoring,
  closeAlarmScoring,
  showShareVideo,
  closeShareVideo,
  showFreeForm,
  closeFreeForm,
  setFreeFormData,
};
