import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row, FormGroup, Label, Input } from 'reactstrap'; // Import FormGroup, Label, Input
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import EyeMessage from '../../components/MessageEye';
import { estimatedTimeArrival, selectedQuestions, responding } from '../../actions/confirm';
import ChatLogo from '../../components/ChatLogo';

export const ETA_QUESTION = 'ETA--question';
export const ETA_QUESTION_NEW = 'ETA--new-questions';
export const ETA_HERE = 'ETA--here';
export const ETA_15 = 'ETA--15';
export const ETA_30 = 'ETA--30';
export const ETA_30_MORE = 'ETA--30+';
export const NOT_RESPONDING = 'I\'m Not Responding';

export enum ETA {
  onsite = 'Onsite',
  here = 0,
  less15min = '<15',
  less30min = '<30',
  more30min = '30 plus',
  notResponding = 'Not Responding'
}


const handleSelectionChange = () => {
  };

const ShareVideo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const respond = (isResponding: boolean) => (e: React.SyntheticEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(responding(isResponding));
  };
  const eta = (time: ETA) => (e: React.SyntheticEvent<HTMLFormElement>): void => {
    respond(true)
    e.preventDefault();
    console.log("ETA: ");
    console.log(time)
    dispatch(estimatedTimeArrival(time));
  };
  const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
     e.preventDefault();
    dispatch(selectedQuestions(e.target.value));
  };

    return (
    <>
      <div className={cx('ETA--root')}>
        <ChatLogo />
        <Container className={cx('ETA--confirmMessage')} expand="lg">
          <Row className={cx('ETA--confirmHeader')}>
            <Col>{t(ETA_QUESTION_NEW)}</Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Button className={'ETA--b'} onClick={eta(ETA.onsite)} color="light" block>
                <strong>{t(ETA_HERE)}</strong>
              </Button>
            </Col>
            <Col xs={6}>
              <Button className={'ETA--b'} onClick={eta(ETA.less15min)} color="light" block>
                <strong>{t(ETA_15)}</strong>
              </Button>
            </Col>
          </Row>
          <Row className={'ETA--row'}>
            <Col xs={6}>
              <Button className={'ETA--b'} onClick={eta(ETA.less30min)} color="light" block>
                <strong>{t(ETA_30)}</strong>
              </Button>
            </Col>
            <Col xs={6}>
              <Button className={'ETA--b'} onClick={eta(ETA.more30min)} color="light" block>
                <strong>{t(ETA_30_MORE)}</strong>
              </Button>
            </Col>
          </Row>
          <Row className={'ETA--respond'}>
            <Col xs={6}>
              <Button className={'ETA--b'} onClick={eta(ETA.notResponding)} color="light" block>
                <strong>{t(NOT_RESPONDING)}</strong>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={'ETA--eyeMessage'}>
        <EyeMessage />
      </div>
    </>
  );

  // return (
  //   <>
  //     <div className={cx('ETA--root')}>
  //       <ChatLogo />
  //       <Container className={cx('ETA--confirmMessage')} expand="lg">
  //         <Row className={cx('ETA--confirmHeader')}>
  //           <Col>{t(ETA_QUESTION)}</Col>
  //         </Row>
  //         <Row>
  //           <Col xs={6}>
  //             <Button className={'ETA--b'} onClick={eta(ETA.here)} color="light" block>
  //               <strong>{t(ETA_HERE)}</strong>
  //             </Button>
  //           </Col>
  //           <Col xs={6}>
  //             <Button className={'ETA--b'} onClick={eta(ETA.less15min)} color="light" block>
  //               <strong>{t(ETA_15)}</strong>
  //             </Button>
  //           </Col>
  //         </Row>
  //         <Row className={'ETA--row'}>
  //           <Col xs={6}>
  //             <Button className={'ETA--b'} onClick={eta(ETA.less30min)} color="light" block>
  //               <strong>{t(ETA_30)}</strong>
  //             </Button>
  //           </Col>
  //           <Col xs={6}>
  //             <Button className={'ETA--b'} onClick={eta(ETA.more30min)} color="light" block>
  //               <strong>{t(ETA_30_MORE)}</strong>
  //             </Button>
  //           </Col>
  //         </Row>
  //       </Container>
  //     </div>
  //     <div className={'ETA--eyeMessage'}>
  //       <EyeMessage />
  //     </div>
  //   </>
  // );
};

export default ShareVideo;
