import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import Audio from '../Audio';
import { useDispatch } from 'react-redux';
import { setMedia } from '../../actions/app';
import ChatLogo from '../ChatLogo';

interface IProps {
  clip: IClipAPI;
  isLogoHidden?: boolean;
}

const PlayIcon = () => (
  <>
    <div className={'MessageBot--background'}></div>
    <div className={'MessageBot--circle'}></div>
    <div className={'MessageBot--triangle'}></div>
  </>
);

export const Clip = ({ isLogoHidden, clip }: IProps) => {
  const dispatch = useDispatch();
  const showVideo = () => dispatch(setMedia(clip));

  const timeline = (
    <>
      <div className={'MessageBot--span'}></div>
      <div className={cx('MessageBot--time', 'text-grey2')}>{moment(clip.created_at).format('LT')}</div>
    </>
  );

  const isAudio = /audio/.test(clip.media_type);

  const poster = (clip && clip.thumbnail_url) || '';
  const url = (clip && (clip.media_url || clip.video)) || '';

  return (
    <div className={cx('MessageBot--root')}>
      <div className={'MessageBot--timeline'}>{timeline}</div>
      <div className={'MessageBot--messageline'}>
        <ChatLogo isIconHidden={isLogoHidden} />
        <div className={'MessageBot--content'}>
          <div className={'MessageBot--message'}>{clip.name}</div>
          {url && (
            <div onClick={showVideo} className={'MessageBot--poster'}>
              <div
                className={cx('MessageBot--image', { ['MessageBot--audioContainer']: isAudio })}
                style={{ backgroundImage: `url(${poster})` }}
              />
              <div className={'MessageBot--play'}>
                {isAudio ? <Audio className={'MessageBot--audio'} /> : <PlayIcon />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Clip;
