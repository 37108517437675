import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getIncident } from '../../reducers/incident';
import cx from 'classnames';

import React from 'react';
import { Button, Col, Row, Container } from 'reactstrap';
import { requestAlarm } from '../../actions/confirm';
import { getConfirm, IConfirm } from '../../reducers/confirm';
import { IAction } from '../../utils/redux-create-reducer';
import { scrollToBottom, useStopTouchMove } from '../../utils/helpers';

export const CANCEL_ALARM = 'alarmConfirmation--cancel-alarm';
export const VERIFY_ALARM = 'alarmConfirmation--verify-alarm';

const AlarmConfirmation = () => {
  const { t } = useTranslation();
  let { cs_release_at, created_at } = useSelector(getIncident, shallowEqual);
  let date_cs_release_at = new Date(cs_release_at);
  let date_created_at = new Date(created_at);

  let govUser = date_cs_release_at.getUTCFullYear() === date_created_at.getUTCFullYear() &&
      date_cs_release_at.getUTCMonth() === date_created_at.getUTCMonth() &&
      date_cs_release_at.getUTCDate() === date_created_at.getUTCDate() &&
      date_cs_release_at.getUTCHours() === date_created_at.getUTCHours() &&
      date_cs_release_at.getUTCMinutes() === date_created_at.getUTCMinutes();

  const touchmoveRef = useStopTouchMove();
  const dispatch = useDispatch();
  const confirm: IConfirm = useSelector(getConfirm, shallowEqual);
  const handle = (action: () => IAction) => (e: React.SyntheticEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(action());
    setTimeout(() => {
      scrollToBottom('feed');
    }, 500);
  };

  return !confirm.isAlarmProcessed && !govUser ? (
    <div ref={touchmoveRef}>
      <Container className={'AlarmConfirmation--root'} expand="lg">
        <Row>
          <Col xs={6}>
            <Button
              onClick={handle(() => requestAlarm(false))}
              className={cx('AlarmConfirmation--cancel')}
              disabled={confirm.cancelAlarmRequested}
              color="green"
              block
            >
              {t(CANCEL_ALARM)}
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              onClick={handle(() => requestAlarm(true))}
              className={cx('AlarmConfirmation--verify')}
              disabled={confirm.verifyAlarmRequested}
              block
            >
              {t(VERIFY_ALARM)}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
};

export default AlarmConfirmation;
