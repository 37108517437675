import { call, put, select, take } from 'redux-saga/effects';
import { getLocation } from 'connected-react-router';
import {
  authorize,
  confirmTerms,
  LS_USER_CONFIRMATION,
  setLoading,
  initialize,
  sessionExpired,
  CONFIRM_TERMS_AND_CONDTITIONS,
} from '../actions/app';
import { fetch } from '../actions/incident';
import { setItem, getItem, removeItem } from '../utils/localStorage';
import { postGetIncidentContactAuth, getGetIncidentContact, api } from '../utils/api';
import get from 'lodash/get';
import { makeCustomerTypes } from '../utils/helpers';

export default function* auth() {
  try {
    const lsToken = yield call(getItem, 'token');
    const { pathname } = yield select(getLocation);

    let { hash: token } = makeCustomerTypes(pathname);

    if (lsToken && token && lsToken !== token) {
      setItem(LS_USER_CONFIRMATION, '0');
    }

    if (!token) {
      token = lsToken;
    }

    setItem('token', token);

    const isConfirmed = getItem(LS_USER_CONFIRMATION);

    if (token) {
      try {
        yield put(setLoading(true));
        const { data } = yield call(getGetIncidentContact, token);
        yield put(setLoading(false));
        if (data.has_pin) {
          alert('enter pin');
        }
        if (Number(isConfirmed)) {
          yield put(confirmTerms());
        } else {
          yield take(CONFIRM_TERMS_AND_CONDTITIONS);
        }
        yield put(setLoading(true));
        const { data: auth } = yield call(postGetIncidentContactAuth, token);
        setItem('bearerToken', auth.bearer_token);
        api.defaults.headers.__token = token;
        api.defaults.headers.__incidentId = auth.incident_id;
        api.defaults.headers.common['Authorization'] = `Token ${auth.bearer_token}`;
        yield put(authorize({ token, bearerToken: auth.bearer_token, incidentId: auth.incident_id }));
        yield put(fetch());
      } catch (err) {
        console.error(err);
        removeItem('token');
        removeItem('bearerToken');
        const status = get(err, 'response.status');
        if (status == 410) {
          yield put(sessionExpired());
        }
      } finally {
        yield put(initialize());
      }
    }
  } catch (err) {
    console.log(err);
  }
}
