import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row, FormGroup, Label, Input } from 'reactstrap'; // Import FormGroup, Label, Input
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';  // Import useState
import EyeMessage from '../../components/MessageEye';
import {alarmScoring, estimatedTimeArrival} from '../../actions/confirm';
import ChatLogo from '../../components/ChatLogo';

export const ALARM_SCORING_PROMPT = 'Do you have confirmation of: (check all that apply)';
export const ALARM_SCORING_LOCATION = 'Person(s) at location';
export const ALARM_SCORING_DAMAGE = 'evidence of property damage';
export const ALARM_SCORING_SAFETY = 'threat to life';
export const ALARM_SCORING_UNSURE = 'I\'m unsure what caused the alarm';

const AlarmScoring = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);

  const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, checked } = e.target;

    if (value === ALARM_SCORING_UNSURE && checked) {
        setSelectedQuestions([ALARM_SCORING_UNSURE]);
    } else if (selectedQuestions.includes(ALARM_SCORING_UNSURE)) {
        setSelectedQuestions([value]);
    } else {
        if (checked) {
            setSelectedQuestions(prevState => [...prevState, value]);
        } else {
            setSelectedQuestions(prevState => prevState.filter(item => item !== value));
        }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(alarmScoring(selectedQuestions));
    console.log("Form submitted with questions:", selectedQuestions);
  };

    return (
    <>
      <div className={cx('ETA--root')}>
        <ChatLogo />
        <Container className={cx('ETA--confirmMessage')} expand="lg">
          <form onSubmit={handleSubmit}>
            <div className={'ETA--confirmHeader'}>
              <div>Do you have confirmation of: (Check all that apply)</div>
              <div className={'alarm-scoring-checkbox-group'}>
                  <FormGroup className={'alarm-scoring-check'} style={{ paddingBottom: '8px', paddingTop: '8px' }} check>
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={handleSelectionChange}
                          value={ALARM_SCORING_LOCATION}
                          checked={selectedQuestions.includes(ALARM_SCORING_LOCATION)}
                        />
                        <div style={{ paddingLeft: '4px' }}>{t(ALARM_SCORING_LOCATION)}
                        <strong> or </strong>
                          {` ${ALARM_SCORING_DAMAGE}`}</div>
                      </Label>
                  </FormGroup>
                  <FormGroup className={'alarm-scoring-check'} style={{ paddingBottom: '8px' }} check>
                      <Label check>
                          <Input
                            type="checkbox"
                            onChange={handleSelectionChange}
                            value={ALARM_SCORING_DAMAGE}
                            checked={selectedQuestions.includes(ALARM_SCORING_DAMAGE)}
                        />
                        <div style={{ paddingLeft: '4px' }}>{t(ALARM_SCORING_LOCATION)}
                        <strong> and </strong>
                          {` ${ALARM_SCORING_DAMAGE}`}</div>
                      </Label>
                  </FormGroup>
                  <FormGroup className={'alarm-scoring-check'} style={{ paddingBottom: '8px' }} check>
                      <Label check>
                        <Input
                            type="checkbox"
                            onChange={handleSelectionChange}
                            value={ALARM_SCORING_SAFETY}
                            checked={selectedQuestions.includes(ALARM_SCORING_SAFETY)}
                        />
                        <div style={{ paddingLeft: '4px' }}>{t(ALARM_SCORING_LOCATION)}
                        <strong> and </strong>
                          {` ${ALARM_SCORING_SAFETY}`}</div>
                      </Label>
                  </FormGroup>
                  <FormGroup className={'alarm-scoring-check'} check>
                      <Label check>
                        <Input
                            type="checkbox"
                            onChange={handleSelectionChange}
                            value={ALARM_SCORING_UNSURE}
                            checked={selectedQuestions.includes(ALARM_SCORING_UNSURE)}
                        />
                        <div style={{ paddingLeft: '4px' }}>{t(ALARM_SCORING_UNSURE)}</div>
                      </Label>
                  </FormGroup>
              </div>
               </div>
            <div className={'parent-button'}>
              <Button className={'ETA--b'} type="submit" color="light" style={{ width: '90px' }}><strong>Submit</strong></Button>
            </div>
          </form>
        </Container>
      </div>
      <div className={'AlarmScoring--eyeMessage'}>
        <EyeMessage />
      </div>
    </>
  );
};

export default AlarmScoring;
