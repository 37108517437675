import React, { useRef, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateInput, setKeyboardState, inputSubmit } from '../../actions/input';
import debounce from 'lodash/debounce';
import { ReactComponent as SendButtonIcon } from '../../assets/icons/sendButton.svg';
import cx from 'classnames';
import { getInput } from '../../reducers/input';
import { useStopTouchMove } from '../../utils/helpers';
import useCustomerType from "../../hooks/use-customer-type";
import {getFeatureFlags, IFeatureFlags} from "../../reducers/featureFlags";
import {select} from "redux-saga/effects";

export const TYPE_HERE = 'input--type-here';
export const ADT_AGENTS_CANT_SEE = 'adt-agents-can-not-see-chat';

const InputField = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editRef = useRef(document.createElement('div'));
  const { isKeyboardVisible } = useSelector(getInput, shallowEqual);
  const { customerTypes }: IFeatureFlags = useSelector(getFeatureFlags, shallowEqual);


  const fillMessage = debounce((msg: string) => {
    dispatch(updateInput(msg));
  }, 300);

  const submit = useCallback(
    (e?: React.SyntheticEvent<HTMLFormElement>): void => {
      const message = editRef.current;
      if (message !== null && message.innerText.trim()) {
        dispatch(inputSubmit(message.innerText.trim()));
        message.innerText = '';
      }
      if (e) {
        e.preventDefault();
      }
    },
    [dispatch],
  );

  const setKeyBoardVisible = debounce((flag: boolean) => {
    if (flag) {
      window.scrollTo(0, window.outerHeight);
    }
    const ifInputEmpty = new RegExp(/\S/).test(editRef.current.innerText);
    if (!ifInputEmpty) {
      editRef.current.innerText = '';
    }

    dispatch(setKeyboardState(setKeyboardState(flag)));
  }, 100);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if ((event.code === 'Enter' || event.code === 'NumpadEnter') && event.shiftKey) {
        submit();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [submit]);

  const touchmoveRef = useStopTouchMove();

  const { isCommercial} = useCustomerType();
  let adtAgentsCantSeeDiv = (
      <>
        <div
          className={cx('Input--inputContainer-resi', {
            ['Input--inputFocused']: isKeyboardVisible,
          })}
        >
          <div
            id="newMessage"
            ref={editRef}
            className={'Input--textarea'}
            data-ph={t(TYPE_HERE)}
            contentEditable={true}
            onInput={(e: any) => fillMessage(e.target.innerText)}
            onBlur={() => setKeyBoardVisible(false)}
            onFocus={() => setKeyBoardVisible(true)}
          ></div>
          <div className={'Input--button'}>
            <SendButtonIcon onClick={submit} />
          </div>
        </div>
      </>
  );
  if (isCommercial) {
    adtAgentsCantSeeDiv = (
      <>
        <div
          id="adtAgentsCantSee"
          ref={editRef}
          className={'Cantsee'}
        >{t(ADT_AGENTS_CANT_SEE)}</div>
        <div
          className={cx('Input--inputContainer', {
            ['Input--inputFocused']: isKeyboardVisible,
          })}
        >
          <div
            id="newMessage"
            ref={editRef}
            className={'Input--textarea'}
            data-ph={t(TYPE_HERE)}
            contentEditable={true}
            onInput={(e: any) => fillMessage(e.target.innerText)}
            onBlur={() => setKeyBoardVisible(false)}
            onFocus={() => setKeyBoardVisible(true)}
          ></div>
          <div className={'Input--button'}>
            <SendButtonIcon onClick={submit} />
          </div>
        </div>
      </>
    );
  }

  return (
    <form ref={touchmoveRef} className={'Input--root'} onSubmit={submit}>
      {adtAgentsCantSeeDiv}
    </form>
  );
};

export default InputField;
