import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {ReactComponent as AlarmVerifyIcon } from '../../assets/icons/verifyAlarmIcon.svg';
import {ReactComponent as AlarmCancelIcon } from '../../assets/icons/cancelAlarmIcon.svg';
import { Button, Col, Container, Row } from 'reactstrap';
import cx from 'classnames';
import { denyAction, confirmVerifyAlarm, confirmCancelAlarm } from '../../actions/confirm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getConfirm, IConfirm } from '../../reducers/confirm';
import EyeMessage from '../../components/MessageEye';
import { IAction } from '../../utils/redux-create-reducer';
import ChatLogo from '../../components/ChatLogo';

export const CONFIRM_YES = 'alarmConfirmation--yes';
export const CONFIRM_NO = 'alarmConfirmation--no';

const CONFIRM_QUESTION_CANCEL = () => (
  <Trans i18nKey="alarmConfirmation--question-cancel">
    <strong />
  </Trans>
);
const CONFIRM_QUESTION_VERIFY = () => (
  <Trans i18nKey="alarmConfirmation--question-verify">
    <strong />
  </Trans>
);

const Confirm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const confirm: IConfirm = useSelector(getConfirm, shallowEqual);

  const handle = (actions: () => IAction) => (e: React.SyntheticEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(actions());
  };

  const Icon = confirm.verifyAlarmRequested ? AlarmVerifyIcon : AlarmCancelIcon;

  return (
    <>
      <div className={cx('AlarmConfirmationMessage--root')}>
        <ChatLogo />
        <Container
          className={
            confirm.verifyAlarmRequested
              ? cx('AlarmConfirmationMessage--confirmMessage', 'AlarmConfirmationMessage--verify')
              : cx('AlarmConfirmationMessage--confirmMessage', 'AlarmConfirmationMessage--cancel')
          }
          expand="lg"
        >
          <Row className={cx('AlarmConfirmationMessage--confirmHeader')}>
            <Col xs={2}>
              <div className={'AlarmConfirmationMessage--confirmIcon'}>
                <Icon />
              </div>
            </Col>
            <Col xs={10}>{confirm.verifyAlarmRequested ? CONFIRM_QUESTION_VERIFY() : CONFIRM_QUESTION_CANCEL()}</Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Button className={'AlarmConfirmationMessage--button'} onClick={handle(denyAction)} color="light" block>
                <span>{t(CONFIRM_NO)}</span>
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className={'AlarmConfirmationMessage--button'}
                onClick={confirm.verifyAlarmRequested ? handle(confirmVerifyAlarm) : handle(confirmCancelAlarm)}
                color="light"
                block
              >
                <span>{t(CONFIRM_YES)}</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={'AlarmConfirmationMessage--eyeMessage'}>
        <EyeMessage />
      </div>
    </>
  );
};

const ConfirmMessage = ({ id, className }: IMessagePeace) => {
  return (
    <div id={id} className={className}>
      <Confirm />
    </div>
  );
};

export default ConfirmMessage;
