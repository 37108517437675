import createReducer from '../utils/redux-create-reducer';
import features from '../features.json';

type Features = typeof features;

export interface IFeatureFlags {
  flags: Partial<Features>;
  customerTypes: {
    isCommercial: boolean;
    isNational: boolean;
    isResidential: boolean;
    type: 'national' | 'commercial' | 'residential';
  };
}

interface IState {
  featureFlags: IFeatureFlags;
}

export const initialState: IFeatureFlags = {
  flags: {},
  customerTypes: {
    isCommercial: false,
    isNational: false,
    isResidential: false,
    type: 'national',
  },
};

export const getFeatureFlags = (state: IState): IFeatureFlags => state.featureFlags;

export default createReducer(initialState, {
  ['FEATURE_FLAGS/FLAGS']: flags => ({ flags }),
  ['FEATURE_FLAGS/CUSTOMER_TYPE']: customerTypes => ({ customerTypes }),
});
