import React from 'react';
import styles from './UpdateNotice.module.scss';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { getIncident, IIncident } from '../../reducers/incident';
import { shallowEqual, useSelector } from 'react-redux';
import { getChat, IChat } from '../../reducers/chat';

interface IProps {
  timestamp: Date | string;
  type: MessageType;
}

export const AlertStatus = ({type, timestamp }: IProps) => {
  const { t } = useTranslation();

  const VERIFIED_BY = (
    <Trans i18nKey={"3g-message"}>
      <strong />
    </Trans>
  );

  return (
    <div className={cx(styles.root)}>
        <div className={styles.messageline}>
          <div className={styles.content}>
            <div className={styles.message}>
           <strong>{VERIFIED_BY}</strong></div>
        </div>
        </div>
      </div>
  );
};

const MessageOwner = ({ data, id, className, messageType }: IMessagePeace) => {
  const { user } = data as IUserMessageData;
  return (
    <div id={id} className={className}>
      <AlertStatus timestamp={data.timestamp} type={messageType as MessageType}/>
    </div>
  );
};

export default MessageOwner;
