import { call, put, select } from 'redux-saga/effects';
import pubnub from '../utils/pubnub';
import { scrollToBottom, isFeedBottom } from '../utils/helpers';
import { delay } from 'redux-saga/effects';
import {
  DEFAULT_MESSAGE,
  EVENT_CALL_POLICE,
  EVENT_DISARM_ALARM,
  EVENT_MESSAGE,
  SET_AGENT_OPENED,
  SIGNAL_TRANSMISSION_FAILED
} from '../actions/chat';
import { getApp } from '../reducers/app';
import { IAuthObject } from '../actions/app';
import get from 'lodash/get';
import { getChat, IChat } from '../reducers/chat';
import moment from 'moment';
import {incidentFetch} from "./incident";

const getPubNubMessage = () =>
  new Promise(s => {
    pubnub.setHandler(s);
  });

const eventMapping: string[] = [
  'Incident automatically closed',
  'CS Incident automatically closed',
  'CS Operator dispatched authorities',
  'CS Incident left queue',
  'CS Operator accessed incident'
]

export default function* chat() {
  while (true) {
    try {
      const { type, payload } = yield call(getPubNubMessage);
      const body = get(payload, 'payload.body');
      console.log("Got Pubnub Message payload", payload)
      console.log("Got Pubnub Message type", type)
      const { list }: IChat = yield select(getChat);
      const { token }: IAuthObject = yield select(getApp);
      const isExist = list.some(
        (msg: IMessage) =>
          moment(msg.data.timestamp).isSame(get(payload, 'payload.timestamp')) &&
          `@PUBNUB/${type}` === EVENT_MESSAGE &&
          get(msg, 'data.text') === body,
      );
      if (!isExist && body !== 'The Incident has timed out.') {
        yield put({ type: `@PUBNUB/${type}`, payload })
      }
      if (isFeedBottom() || (`@PUBNUB/${type}` === EVENT_MESSAGE && payload.sender.token === token)) {
        yield call(scrollToBottom, 'feed');
      }
      console.log("payload.type", payload.type)
      console.log("pubnub type", `@PUBNUB/${type}`)
      //If pubnub is a status change run incidentFetch
      if (`@PUBNUB/${type}` != EVENT_MESSAGE
        && `@PUBNUB/${type}` != DEFAULT_MESSAGE
        && `@PUBNUB/${type}` != SIGNAL_TRANSMISSION_FAILED) {
        // If pubnub is not a message call incidentFetch
        const incidentData = yield call(incidentFetch, true);
        console.log("incidentData");
        console.log(incidentData);
      }
      if (`@PUBNUB/${type}` == DEFAULT_MESSAGE && payload.type == 'CS Operator dispatched authorities') {
        // If pubnub is not a message call incidentFetch
        const incidentData = yield call(incidentFetch, true);
        console.log("incidentData");
        console.log(incidentData);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
