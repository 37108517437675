import React from 'react';
import moment from 'moment';
import { convertName } from '../../utils/helpers';
import cx from 'classnames';

interface IProps {
  timestamp: Date | string;
  isTimestampHidden?: boolean;
  name: string;
  isIconHidden?: boolean;
  message: string;
}

export const Owner = ({ message, timestamp, name, isIconHidden, isTimestampHidden }: IProps) => {
  const timeline = (
    <>
      <div className={cx('MessageOwner--time', 'text-grey2')}>You at {moment(timestamp).format('LT')}</div>
      <div className={'MessageOwner--span'}></div>
    </>
  );

  return (
    <div className={cx('MessageOwner--root', 'MessageOwner--owner')}>
      <div className={'MessageOwner--timeline'}>{isTimestampHidden && timeline}</div>
      <div className={'MessageOwner--messageline'}>
        <div className={cx('MessageOwner--message', 'text-left text-light bg-moonBlue')}>{message}</div>
        <div className={'MessageOwner--icon'}>
          {isIconHidden && (
            <div
              className={cx('contact-icon text-light bg-moonBlue', 'MessageOwner--contactIcon', {
                ['MessageOwner--textWrapper']: convertName(name).includes('W'),
              })}
            >
              {convertName(name)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MessageOwner = ({ data, id, className, isFirstMessage, isLastMessage }: IMessagePeace) => {
  const { text, user, timestamp } = data as IUserMessageData;
  return (
    <div id={id} className={className}>
      <Owner
        message={text}
        name={user.name}
        timestamp={timestamp}
        isIconHidden={(!isLastMessage && isFirstMessage) || (isLastMessage && isFirstMessage)}
        isTimestampHidden={(!isFirstMessage && isLastMessage) || isLastMessage}
      />
    </div>
  );
};

export default MessageOwner;
