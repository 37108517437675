/* eslint-disable @typescript-eslint/camelcase */
import { AxiosResponse } from 'axios';
import clips from '../_dev_clips.json';
const RESET_ALL = 'test ivn reset all';
const ADDRESS = 'test ivn address';
const ZONE = 'test ivn zone';
const CLIP = 'test ivn add clip';
const LIVEVIEW = 'test ivn add liveview';
const LEFT_Q = 'test ivn left q';
const AUTO_CLOSE = 'test ivn auto close';

const LS_ADDRESS = 'ivntest-address';
const LS_ZONE = 'ivntest-zone';
const LS_CLIPS = 'ivntest-clips';
const LS_LIVEVIEWS = 'ivntest-liveviews';

const makeLV = (url_mjpg: string) => ({
  type: 'video',
  url_flv: '',
  url_mjpg,
  url_mjpg_proxy: '',
  timeout: '600',
  created_at: '2019-01-31T02:12:54.251Z',
});

const makeClip = (video?: string, image?: string) => ({
  video,
  image,
  name: video || image || '',
});

export const getFakeResponse = (): IFakeIncResponse => {
  const address = localStorage.getItem(LS_ADDRESS);
  const zone = localStorage.getItem(LS_ZONE);
  // const clips = localStorage.getItem(LS_CLIPS);
  const liveviews = localStorage.getItem(LS_LIVEVIEWS);
  const data = {
    address,
    zone,
    liveviews: liveviews ? JSON.parse(liveviews) : [],
    clips,
  };

  return Object.keys(data).reduce(
    (result, key) => ({ ...result, ...(data[key] ? { [key]: data[key] } : {}) }),
    {},
  ) as IFakeIncResponse;
};
export const detectTestMessage = (message: string) => {
  return new RegExp(`${ADDRESS}|${ZONE}|${CLIP}|${LIVEVIEW}|${RESET_ALL}|${LEFT_Q}|${AUTO_CLOSE}`).test(message);
};

export const execTestMessage = (message: string) => {
  const [key, v] = message.split('=');
  const value = (v || '').trim();
  switch (key.trim()) {
    case RESET_ALL:
      {
        localStorage.removeItem(LS_ADDRESS);
        localStorage.removeItem(LS_ZONE);
        localStorage.removeItem(LS_LIVEVIEWS);
        localStorage.removeItem(LS_CLIPS);
      }
      break;
    case ADDRESS:
      {
        if (value === 'reset') {
          localStorage.removeItem(LS_ADDRESS);
        } else {
          localStorage.setItem(LS_ADDRESS, value);
        }
      }
      break;
    case ZONE:
      {
        if (value === 'reset') {
          localStorage.removeItem(LS_ZONE);
        } else {
          localStorage.setItem(LS_ZONE, value);
        }
      }
      break;
    case CLIP:
      {
        if (value === 'reset') {
          localStorage.removeItem(LS_CLIPS);
        } else {
          const maybeClips = localStorage.getItem(LS_CLIPS);
          const clips = maybeClips ? JSON.parse(maybeClips) : [];
          const [video, poster] = value.split(',');
          clips.push(makeClip(video, poster));
          localStorage.setItem(LS_CLIPS, JSON.stringify(clips));
        }
      }
      break;
    case LIVEVIEW:
      {
        if (value === 'reset') {
          localStorage.removeItem(LS_LIVEVIEWS);
        } else {
          const maybeLV = localStorage.getItem(LS_LIVEVIEWS);
          const LV = maybeLV ? JSON.parse(maybeLV) : [];
          LV.push(makeLV(value));
          localStorage.setItem(LS_LIVEVIEWS, JSON.stringify(LV));
        }
      }
      break;
    case LEFT_Q:
      return 'left_q';
    case AUTO_CLOSE:
      return 'auto_close';
    default:
      console.log([key, value]);
  }
  return null;
};

interface IFakeIncResponse {
  address?: string;
  zone?: string;
  clips: any[];
  liveviews: any[];
  time_to_extend?: string;
}

export const modifyResponse = (response: AxiosResponse): AxiosResponse => {
  const fakeResponse = getFakeResponse();
  if (fakeResponse.address) {
    response.data.site.address = fakeResponse.address;
  }

  if (fakeResponse.zone) {
    response.data.zones =
      response.data.zones && response.data.zones.length > 0
        ? response.data.zones.map((z: IZoneAPI) => ({ ...z, description: fakeResponse.zone }))
        : [
            {
              ivnid: response.data.ivnid,
              description: fakeResponse.zone,
              event_type: 'Alarm',
              event_type_ivnid: '09fd80c2-b91d-434c-9c69-84010286db5a',
              zone: '1',
              camera_number: null,
              status: 'initial',
            },
          ];
  }

  if (fakeResponse.liveviews.length) {
    response.data.can_liveview = 'true';
    response.data.liveviews = fakeResponse.liveviews;
  }

  if (fakeResponse.clips.length) {
    response.data.can_clip = true;
    response.data.clips = fakeResponse.clips;
  }

  if (fakeResponse.time_to_extend) {
    response.data.time_to_extend = fakeResponse.time_to_extend;
  }

  return response;
};
