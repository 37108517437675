import { useLocation } from 'react-router-dom';
import residentialLogo from '../assets/icons/octagon-logo.svg';
import commercialLogo from '../assets/icons/commercial-logo.png';
import residentialAvatar from '../assets/icons/octagon-logo.svg';
import commercialAvatar from '../assets/icons/commercial-avatar.png';
import { makeCustomerTypes } from '../utils/helpers';

interface CustomerTypeImage {
  avatar: any;
  logo: any;
}

interface CustomerTypeImageObject {
  national?: CustomerTypeImage;
  commercial: CustomerTypeImage;
  residential: CustomerTypeImage;
  resicom: CustomerTypeImage;
}

const imagesTypes: CustomerTypeImageObject = {
  national: {
    logo: residentialLogo,
    avatar: residentialAvatar,
  },
  residential: {
    logo: residentialLogo,
    avatar: residentialAvatar,
  },
  commercial: {
    logo: commercialLogo,
    avatar: commercialAvatar,
  },
  resicom: {
    logo: residentialLogo,
    avatar: residentialAvatar,
  },
};

export const useCustomerType = () => {
  const { pathname } = useLocation();

  const types = makeCustomerTypes(pathname);

  return {
    ...types,
    images: (imagesTypes[types.type] || imagesTypes.residential) as CustomerTypeImage,
  };
};

export default useCustomerType;
