import React from 'react';
import useCustomerType from '../../hooks/use-customer-type';

export const ChatLogo = ({ isIconHidden }: { isIconHidden?: boolean }) => {
  const { isCommercial, images } = useCustomerType();
  return (
    <div className={isCommercial ? 'ChatLogo--iconCommercial' : 'ChatLogo--icon'}>
      {!isIconHidden && <img src={images.avatar} alt="icon" />}
    </div>
  );
};

export default ChatLogo;
