import { put, select, call, takeEvery } from 'redux-saga/effects';
import { INPUT_SUBMIT, inputClear } from '../actions/input';
import { postMessage, postLeftQ, postAutoClose } from '../utils/api';
import { detectTestMessage, execTestMessage } from '../utils/QATools';
import { getInput, IInput } from '../reducers/input';
import pack from '../../package.json';
import { IAction } from '../utils/redux-create-reducer';

export function* inputWorker({ payload }: IAction) {
  const p = pack as any;
  try {
    const { message }: IInput = yield select(getInput);
    const isTestMessage = detectTestMessage(payload || message);
    if (p.developmentMode && isTestMessage) {
      const name = execTestMessage(payload || message);

      if (name === 'left_q') {
        yield postLeftQ();
      }

      if (name === 'auto_close') {
        yield postAutoClose();
      }

      location.reload();

      return;
    }
    yield call(postMessage, { body: payload || message, timestamp: new Date().toISOString() });
    yield put(inputClear());
  } catch (e) {
    console.error(e);
  }
}

export default function* inputSaga() {
  yield takeEvery(INPUT_SUBMIT, inputWorker);
}
