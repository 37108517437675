import React, { ReactNode, useEffect } from 'react';
import ReactDom from 'react-dom';
import cx from 'classnames';
import { ReactComponent as ReportOffIcon } from '../../assets/icons/cancelAlarmGreen.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/Shield.svg';
import { ReactComponent as ShapeIcon } from '../../assets/icons/Subtract.svg';
import { Navbar, NavbarBrand, Nav, NavItem, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { convertName, useStopTouchMove } from '../../utils/helpers';

export interface IProps {
  address: ReactNode;
  title: string;
  contacts: IUser[];
  status: AlarmStatus;
}

const BurglarAlarmHeader = ({ title, contacts, address, status }: IProps) => {
  const popupId = 'sigchat-popupPlaceholder';
  const buttonId = 'sigchat-moreButton-contacts';
  const touchmoveRef = useStopTouchMove();
  const people = contacts
    .map(c => convertName(c.name))
    .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
    .filter((c, i) => i < 4)
    .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? -1 : 1))
    .map((name, i) => (
      <div key={i + name} className={cx('contact-icon', 'BurglarAlarmHeader--circleMultiply')}>
        <div className={cx({ ['BurglarAlarmHeader--textWrapper']: name.includes('W') })}>{name}</div>
      </div>
    ));

  if (contacts.length > 4) {
    people.reverse();
    people.push(
      <div
        key="more"
        id={buttonId}
        className={cx(
          'contact-icon bg-moonBlue text-white',
          'BurglarAlarmHeader--circleMultiply',
          'BurglarAlarmHeader--morePeople',
        )}
      >
        {contacts.length - 4}+
      </div>,
    );
    people.reverse();
  }

  useEffect(() => {
    const target = document.getElementById(buttonId);
    if (target) {
      const popup = (
        <UncontrolledPopover trigger="legacy" placement="auto" target={target} fade={false}>
          <PopoverBody>
            {contacts
              .filter(c => Boolean(c.name))
              .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
              .map(c => (
                <div key={c.name}>{c.name}</div>
              ))}
          </PopoverBody>
        </UncontrolledPopover>
      );

      ReactDom.render(popup, document.getElementById(popupId));
    }
  }, [contacts]);
  return (
    <div ref={touchmoveRef}>
      <Navbar expand="lg" className={'BurglarAlarmHeader--root'}>
        <NavbarBrand>
          {status === 'disarmed_alarm' && <ReportOffIcon className={'BurglarAlarmHeader--iconAlarmCanceled'} />}
          {['called_police', 'timed_out', 'left_queue', 'auto_close', 'adt_has_notified_police'].includes(status) && (
            <ShapeIcon className={'BurglarAlarmHeader--iconAlarm'} />
          )}
          {status === 'active' && <AlertIcon className={'BurglarAlarmHeader--iconAlarm'} />}
        </NavbarBrand>
        <Nav navbar>
          <NavItem className={'BurglarAlarmHeader--header'}>
            <strong
              className={`text-uppercase ${
                status === 'disarmed_alarm' ? 'BurglarAlarmHeader--disarmed' : 'BurglarAlarmHeader--verified'
              }`}
            >
              {title}
            </strong>
            <div className={cx('text-left', 'BurglarAlarmHeader--address')}>{address}</div>
          </NavItem>
        </Nav>
        <div className={'BurglarAlarmHeader--contacts'}>{people}</div>
        <div id={popupId}></div>
      </Navbar>
    </div>
  );
};

export default BurglarAlarmHeader;
