import React from 'react';

export const Audio = ({ className }: { className: string }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9802 4.0002C12.9868 4.00007 12.9934 4 13 4H12.96C12.9667 4 12.9734 4.00007 12.9802 4.0002Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.49 4.14C12.6387 4.05179 12.8075 4.00368 12.9802 4.0002C13.2381 4.0054 13.4843 4.11011 13.6671 4.29289C13.8546 4.48043 13.96 4.73478 13.96 5V18.72C13.96 18.9852 13.8546 19.2396 13.6671 19.4271C13.4796 19.6146 13.2252 19.72 12.96 19.72C12.7809 19.7172 12.6054 19.669 12.45 19.58L8.8 17.42C8.19427 17.0605 7.50434 16.8673 6.8 16.86H3C2.73478 16.86 2.48043 16.7546 2.29289 16.5671C2.10536 16.3796 2 16.1252 2 15.86V7.86C2 7.59478 2.10536 7.34043 2.29289 7.15289C2.48043 6.96536 2.73478 6.86 3 6.86H6.85C7.55434 6.85268 8.24427 6.65951 8.85 6.3L12.49 4.14ZM13 18.72V5L9.36 7.16C8.58822 7.61795 7.70742 7.85974 6.81 7.86H3V15.86H6.8C7.69617 15.8597 8.57628 16.0978 9.35 16.55L13 18.72Z"
    />
    <path d="M17.6271 6.50988C17.7652 6.27077 18.071 6.18893 18.3101 6.32707C19.2811 6.88804 20.0873 7.69464 20.6478 8.66584C21.2084 9.63705 21.5035 10.7387 21.5035 11.86C21.5035 12.9814 21.2084 14.083 20.6478 15.0542C20.0873 16.0254 19.2811 16.832 18.3101 17.3929C18.071 17.5311 17.7652 17.4492 17.6271 17.2101C17.4889 16.971 17.5708 16.6652 17.8099 16.5271C18.6289 16.0539 19.3089 15.3735 19.7817 14.5543C20.2546 13.7351 20.5035 12.8059 20.5035 11.86C20.5035 10.9141 20.2546 9.98493 19.7817 9.16571C19.3089 8.3465 18.6289 7.66613 17.8099 7.19295C17.5708 7.0548 17.4889 6.74898 17.6271 6.50988Z" />
    <path d="M16.1309 8.69881C16.2973 8.47839 16.6108 8.43455 16.8312 8.6009C17.3357 8.98161 17.7478 9.47127 18.0367 10.0334C18.3256 10.5955 18.4839 11.2156 18.4999 11.8474C18.5001 11.8568 18.5001 11.8662 18.4998 11.8756C18.4802 12.5019 18.3214 13.116 18.0349 13.6732C17.7485 14.2305 17.3415 14.717 16.8435 15.0973C16.6241 15.265 16.3103 15.223 16.1427 15.0035C15.9751 14.7841 16.0171 14.4703 16.2365 14.3027C16.6164 14.0124 16.927 13.6412 17.1456 13.216C17.362 12.795 17.4829 12.3315 17.4998 11.8585C17.4857 11.382 17.3653 10.9146 17.1473 10.4905C16.9271 10.0622 16.6132 9.68917 16.2288 9.3991C16.0084 9.23276 15.9646 8.91923 16.1309 8.69881Z" />
  </svg>
);

export default Audio;
