import React from 'react';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { confirmTerms, LS_USER_CONFIRMATION } from '../../actions/app';
import { setItem } from '../../utils/localStorage';
import { useTranslation } from 'react-i18next';
import HelpfulLinks from '../../components/HelpfulLinks';
import cx from 'classnames';
import useCustomerType from '../../hooks/use-customer-type';
import { useLinks } from '../../hooks/use-links';
export const TERMS_AND_CONDITIONS_ACCESS = 'termAndConditions--access';
export const TERMS_AND_CONDITIONS_CONDITION = 'termAndConditions--condition';
export const TERMS_AND_CONDITIONS_TITLE = 'termAndConditions--title';
export const TERMS_AND_CONDITIONS_GO = 'termAndConditions--go';

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const helpfulLinks = useLinks();
  const { images } = useCustomerType();

  const joinToChat = () => {
    setItem(LS_USER_CONFIRMATION, '1');
    dispatch(confirmTerms());
  };
  return (
    <div className={'TermsAndConditions--root'}>
      <div className={'TermsAndConditions--middle'}>
        <div className={'TermsAndConditions--contentWrap'}>
          <img src={images.logo} alt="icon" />
          <div className={'TermsAndConditions--content'}>
            <div className={'TermsAndConditions--title'}>{t(TERMS_AND_CONDITIONS_ACCESS)}</div>
            <div className={'TermsAndConditions--message'}>{t(TERMS_AND_CONDITIONS_CONDITION)}</div>
            <div className={'TermsAndConditions--terms'}>
              <a target="_blank" rel="noopener noreferrer" href={helpfulLinks.terms.url}>
                {t(TERMS_AND_CONDITIONS_TITLE)}
              </a>
            </div>
            <div className={'TermsAndConditions--button'}>
              <Button onClick={joinToChat} className={cx('TermsAndConditions--buttonBold', 'btn-ADTBlue')} block>
                {t(TERMS_AND_CONDITIONS_GO)}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={'TermsAndConditions--helpfulLinks'}>
        <HelpfulLinks />
      </div>
    </div>
  );
};

export default TermsAndConditions;
