import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerType from '../../hooks/use-customer-type';

export const RECONNECTING_TITLE = 'reconnecting--title';
export const RECONNECTING_MESSAGE = 'reconnecting--message';

const Reconnecting = () => {
  const [t] = useTranslation();
  const { isCommercial, images } = useCustomerType();

  return (
    <>
      <div className={'Reconnecting--root'}>
        <img src={images.logo} alt="icons" style={isCommercial ? { width: '190px' } : {}} />
        <div className={'Reconnecting--content'}>
          <div className={'Reconnecting--title'}>{t(RECONNECTING_TITLE)}</div>
          <div className={'Reconnecting--message'}>{t(RECONNECTING_MESSAGE)}</div>
        </div>
      </div>
      <div className={'Reconnecting--background'}></div>
    </>
  );
};

export default Reconnecting;
