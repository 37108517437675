import React, { Suspense, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Root from './pages/Root';
import Preloader from './components/Preloader';
import { history } from './store';

const App = (): ReactElement => (
  <div className="App">
    <ConnectedRouter history={history}>
      <Suspense fallback={<Preloader />}>
        <Switch>
          <Route path={['/:customerType/:hash', '/:hash', '/']} component={Root} />
          {/* inject usage */}
        </Switch>
      </Suspense>
    </ConnectedRouter>
  </div>
);

export default App;
