import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cx from 'classnames';
import moment from 'moment';
import { ReactComponent as AlarmVerifyIcon } from '../../assets/icons/verifyAlarmIcon.svg';
import { ReactComponent as AlarmCancelIcon } from '../../assets/icons/cancelAlarmIcon.svg';
import ChatLogo from '../ChatLogo';

export const ALARM_VERIFIED_AT = 'alarm-verified-at';

interface IProps {
  name: string;
  type: MessageType;
  timestamp: Date | string;
}

export const AlertStatus = ({ name, type, timestamp }: IProps) => {
  const { t } = useTranslation();
  const isCancel = type === 'cancelConfirmed';
  const initialedKey = isCancel ? 'alarm-canceled-by' : 'alarm-verified-by';
  const Icon = isCancel ? AlarmCancelIcon : AlarmVerifyIcon;

  const VERIFIED_BY = (
    <Trans i18nKey={initialedKey}>
      <strong />
    </Trans>
  );

  return (
    <div className={cx('MessageAlertStatus--root')}>
      <ChatLogo />
      <div
        className={cx('MessageAlertStatus--content', {
          ['MessageAlertStatus--verify']: !isCancel,
          ['MessageAlertStatus--cancel']: isCancel,
        })}
      >
        <div className={'MessageAlertStatus--icon'}>
          <Icon />
        </div>
        <div className={'MessageAlertStatus--message'}>
          {VERIFIED_BY} <strong>{name}</strong> {t(ALARM_VERIFIED_AT)} {moment(timestamp).format('LT')}
        </div>
      </div>
    </div>
  );
};

const MessageAlertStatus = ({ data, id, className, messageType }: IMessagePeace) => {
  const { user } = data as IUserMessageData;
  const currentUtcTime = moment.utc();
  let timestamp = moment.utc(data.timestamp);

  // Check if the timestamp is greater than the current UTC time by 1 minute
  if (timestamp.isAfter(currentUtcTime.add(1, 'minutes'))) {
    timestamp = currentUtcTime;
  }

  const timestampDate = timestamp.toDate(); // Convert Moment object to Date

  return (
    <div id={id} className={className}>
      <AlertStatus name={user.name} type={messageType as MessageType} timestamp={timestampDate} />
    </div>
  );
};



export default MessageAlertStatus;
