// Polyfills to support IE11
import 'react-app-polyfill/ie11';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/map';
import 'core-js/features/object/assign';
import 'core-js/features/object/values';
import 'core-js/features/promise';
import 'core-js/features/map';
import 'core-js/features/set/map';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import './styles/App.scss';
import './i18n/config';
import { changeTheme } from './utils/helpers';
changeTheme('residential');

const rootElement = document.getElementById('root') as HTMLElement;

const render = (Application: React.FC): void => {
  ReactDOM.render(
    <Provider store={store}>
      <Application />
    </Provider>,
    rootElement,
  );
};

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  render(App);
}

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
