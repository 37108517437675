import React from 'react';
import useCustomerType from '../../hooks/use-customer-type';
import spinner from '../../assets/icons/octagon-spinner.gif';

const Preloader = () => {
  const { isCommercial } = useCustomerType();

  const roundSpinner = (
    <svg className={'Preloader--spinner'} viewBox="0 0 50 50">
      <circle className={'Preloader--path'} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
  );
  const octoSpinner = <img className={'Preloader--image'} src={spinner} alt="Spinner" />;
  return (
    <>
      <div className={'Preloader--root'}>{isCommercial ? roundSpinner : octoSpinner}</div>
      <div className={'Preloader--background'}></div>
    </>
  );
};

export default Preloader;
