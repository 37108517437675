import createReducer from '../utils/redux-create-reducer';
import {
  AUTHORIZE,
  CONFIRM_TERMS_AND_CONDTITIONS,
  SET_LOADING,
  INITIAL_READ,
  INITIALIZE,
  SESSION_EXPIRED,
  SET_NETWORK_STATUS,
  IAuthObject,
  SET_MEDIA,
} from '../actions/app';

export interface IAuthState extends IAuthObject {
  authorized: boolean;
  isLoading: boolean;
  isInitialRead: boolean;
  isTermsConfirmed: boolean;
  isInitialized: boolean;
  isDisconnected: boolean;
  isSessionExpired: boolean;
  clip: IClipAPI | null;
}

export const initialState: IAuthState = {
  authorized: false,
  isLoading: false,
  isInitialRead: false,
  isTermsConfirmed: false,
  isInitialized: false,
  isDisconnected: false,
  isSessionExpired: false,
  clip: null,
};

export const getApp = (state: { auth: IAuthState }): IAuthState => state.auth;

export default createReducer(initialState, {
  [CONFIRM_TERMS_AND_CONDTITIONS]: () => ({ isTermsConfirmed: true }),
  [SET_LOADING]: (isLoading: boolean) => ({ isLoading }),
  [INITIAL_READ]: () => ({ isInitialRead: true }),
  [INITIALIZE]: () => ({ isInitialized: true }),
  [SESSION_EXPIRED]: () => ({ isSessionExpired: true }),
  [SET_NETWORK_STATUS]: status => ({ isDisconnected: !status }),
  [AUTHORIZE]: (authObject: IAuthObject) => ({ ...authObject, authorized: true }),
  [SET_MEDIA]: (clip: IClipAPI | null) => ({ clip }),
});
