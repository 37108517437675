import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import cx from 'classnames';
import moment from 'moment';
import kebabCase from 'lodash/kebabCase';
import ChatLogo from '../ChatLogo';
import useCustomerType from '../../hooks/use-customer-type';

export const SESSION_WILL_END = 'session-will-end';
export const ADT_MONITORING_CENTER = 'message-bot--name';
export const MESSAGE_AT = 'alarm-verified-at';
export const TIMED_OUT_COMMERCIAL = 'the-incident-has-timed-out-commercial';

interface IProps {
  timestamp: Date | string;
  text?: string;
}

export const Service = ({ timestamp, text }: IProps) => {
  const { t } = useTranslation();

  const timeline = (
    <>
      <div className={cx('ServiceMessage--time', 'text-grey2')}>
        {t(ADT_MONITORING_CENTER)} {t(MESSAGE_AT)} {moment(timestamp).format('LT')}
      </div>
    </>
  );

  const contentText = text && t(kebabCase(text)) == kebabCase(text) ? text : t(kebabCase(text));

  return (
    <>
      <div className={cx('ServiceMessage--root')}>
        <ChatLogo />
        <div className={'ServiceMessage--messageLine'}>
          <div className={'ServiceMessage--timeline'}>{timeline}</div>
          <Container className={cx('ServiceMessage--confirmMessage')} expand="lg">
            <Row className={cx('ServiceMessage--confirmHeader')}>
              <Col>{text ? contentText : t(SESSION_WILL_END)}</Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const ServiceMessage = ({ data, id, className, messageType }: IMessagePeace) => {
  const { isCommercial } = useCustomerType();
  const { timestamp, text } = data as IUserMessageData;
  const isTimedOut = messageType === 'timedOut';
  const timedOutMessage = isTimedOut && isCommercial ? TIMED_OUT_COMMERCIAL : null;

  if (isTimedOut && !isCommercial) {
    return null;
  }

  return (
    <div id={id} className={className}>
      <Service timestamp={timestamp} text={timedOutMessage || text} />
    </div>
  );
};

export default ServiceMessage;
