import { LOCATION_CHANGE } from 'connected-react-router';
import get from 'lodash/get';
import { take, put } from 'redux-saga/effects';
import { changeTheme, makeCustomerTypes, makeFeatureFlags } from '../utils/helpers';

export default function* featureFlags() {
  while (true) {
    const { payload } = yield take(LOCATION_CHANGE);

    const search = get(payload, 'location.search', '');
    const pathname = get(payload, 'location.pathname', '');

    const cusType = makeCustomerTypes(pathname);
    const flags = makeFeatureFlags(search);

    if (cusType.isCommercial) {
      changeTheme('commercial');
    }

    yield put({ type: 'FEATURE_FLAGS/FLAGS', payload: flags });
    yield put({ type: 'FEATURE_FLAGS/CUSTOMER_TYPE', payload: cusType });
  }
}
