/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useRef } from 'react';
import { matchPath } from 'react-router-dom';
import moment from 'moment';
import { PUBLIC_URL, featureFlags as envFeatures } from '../env';
import first from 'lodash/first';
import features from '../features.json';
import last from 'lodash/last';
import { routes } from '../constants';
import get from 'lodash/get';

export const img = (path: string) => `${PUBLIC_URL}/img/icons${path}`;

export const convertName = (name: string) =>
  (name || '')
    .split(' ')
    .filter(s => s.length > 0)
    .map(s => s[0].toUpperCase())
    .join('');

// export const isScrolledIntoView = (el: Element, parentEl?: HTMLElement | null) => {
//   if (!el) {
//     return false;
//   }
//   const rect = el.getBoundingClientRect();
//   const elemBottom = rect.bottom;

//   // Only completely visible elements return true:
//   const isVisible = elemBottom <= (parentEl ? parentEl.getBoundingClientRect().bottom + 5 : window.innerHeight);
//   // Partially visible elements return true:
//   //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
//   return isVisible;
// };

export const isScrolledIntoView = (el: Element, parentEl?: HTMLElement | null) => {
  if (!el) {
    return false;
  }
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;

  // Only completely visible elements return true:
  const isVisible = elemTop <= (parentEl ? parentEl.getBoundingClientRect().top - 5 : window.innerHeight);
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
};

export const convertSecondsToTime = (sec: number): string => {
  const minutes = parseInt(String(sec / 60));
  const seconds = sec % 60;

  return `${minutes}:${seconds > 9 ? seconds : '0' + seconds}`;
};

export const scrollToBottom = (elId: string) => {
  const el = document.getElementById(elId);
  el && el.scrollTo({ behavior: 'smooth', top: 0 });
};

export const scrollToNewMessages = () => {
  const lastMSG = last(document.querySelectorAll('.unreadMessage'));

  if (lastMSG) {
    lastMSG.scrollIntoView({ block: 'center', behavior: 'smooth' });
  } else {
    scrollToBottom('feed');
  }
};

export const strCode = (a: number[]) => a.map(t => String.fromCharCode(t)).join('');

export const getExpirationSeconds = (expiration: string): number => parseInt(String(moment(expiration).diff() / 1000));
export const cutHtml = (text?: string): string => (text || '').replace(/\<(.*?)>/g, '');

export const vh100 = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const isFeedBottom = () => {
  const feed = document.getElementById('feed');
  // const val = feed && feed.scrollHeight - (feed.getBoundingClientRect().height + feed.scrollTop);
  return Number(feed && feed.scrollTop) < 200;
};

export const tabVisibility = () =>
  new Promise(s => {
    const d = document as any;
    let hidden = 'hidden';
    let visibilityChange = 'visibilitychange';
    if (typeof d.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof d.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    const handleVisibilityChange = () => {
      s(!document[hidden]);
    };

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
      console.log(
        'This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.',
      );
    } else {
      // Handle page visibility change
      document.addEventListener(visibilityChange, handleVisibilityChange, false);
    }
  });

export function sortByDates(a: string | Date, b: string | Date, reverse?: boolean) {
  return moment(reverse ? b : a).unix() - moment(reverse ? a : b).unix();
}
export const useStopTouchMove = () => {
  const ref = useRef<any>();
  useEffect(() => {
    const func = (e: any) => {
      e.preventDefault();
    };
    ref.current && ref.current.addEventListener('touchmove', func);
    return () => ref.current && ref.current.removeEventListener('touchmove', func);
  }, []);

  return ref;
};

export type CustomerType = 'national' | 'commercial' | 'residential' | 'resicom';

const defaultCustomerType: CustomerType = 'residential';

const prefixes: Record<string, CustomerType> = {
  n: 'national',
  c: 'commercial',
  r: 'residential',
  rc: 'resicom',
};

export const getURLParams = (pathname: string) => {
  const matchDefault = matchPath<{ hash?: string }>(pathname, routes.defaultCustomer);
  const matchOther = matchPath(pathname, routes.otherCustomer) || {};

  const { hash: defaultHash } = get(matchDefault, 'params', { hash: '' });
  const { hash: otherHash, customerType } = get(matchOther, 'params', { hash: '', customerType: '' });
  const hash = otherHash || defaultHash || '';

  return { customerType, hash };
};

export const makeCustomerTypes = (pathname: string) => {
  const { hash, customerType } = getURLParams(pathname);

  const type: CustomerType = prefixes[customerType] || defaultCustomerType;

  const isNational = type === 'national';
  const isCommercial = type === 'commercial';
  const isResidential = type === 'residential';
  const isResCom = type === 'resicom';

  return {
    hash,
    type,
    isCommercial,
    isResidential,
    isNational,
    isResCom,
  };
};

export const isCorrectURL = (pathname: string) => {
  const { customerType, hash } = getURLParams(pathname);

  return (!customerType && hash) || Boolean(prefixes[customerType]);
};

export const getTitleKey = (pathname: string) => {
  const { customerType } = getURLParams(pathname);
  const type = prefixes[customerType] || defaultCustomerType;
  return `app-title-${type}`;
};

export const makeFeatureFlags = (search: string) => {
  const parts = new URLSearchParams(search);

  const feats = Object.keys(features);

  return feats
    .map(key => {
      const val = parts.get(key) || features[key] || envFeatures[key];
      return {
        name: key,
        value: val === 'true' || Number(val) === 1,
      };
    })
    .reduce((acc, nxt) => {
      acc[nxt.name] = nxt.value;

      return acc;
    }, {});
};

export const changeTheme = (theme: ADTTheme) => {
  document.documentElement.className = '';
  document.documentElement.classList.add(`theme-${theme}`);
};
