/* eslint-disable @typescript-eslint/camelcase */
import createReducer from '../utils/redux-create-reducer';
import {
  FETCH_INCIDENT,
  FETCH_INCIDENT_SUCCESS,
  FETCH_INCIDENT_FAILURE,
  FETCH_INCIDENT_UPDATE,
} from '../actions/incident';
import { EVENT_ADD_TIME_SUCCESS, INCIDENT_UPDATE, SET_AGENT_OPENED } from '../actions/chat';

export interface IIncident extends IIncidentAPI {
  isLoading?: boolean;
  error?: any;
  //clips: IClipAPI [];
}

interface IState {
  events: IEventAPI[];
  incident: IIncident;
}

export const initialState = {
  isLoading: false,
  error: null,
  agentOpened: false,
};

export const getIncident = (state: IState): IIncident => state.incident;
export const getSite = (state: IState): ISiteAPI => state.incident.site || {};
export const getContacts = (state: IState): IContactAPI[] => state.incident.contacts || [];
export const getEvents = (state: IState): IEventAPI[] => state.events || [];
//export const getClips = (state: IState): IClipAPI[] => state.incident.clips || [];

export default createReducer(initialState, {
  [FETCH_INCIDENT]: () => ({ isLoading: true }),
  [FETCH_INCIDENT_SUCCESS]: data => ({ ...data, isLoading: false }),
  [FETCH_INCIDENT_UPDATE]: data => ({ ...data, isLoading: false }),
  [FETCH_INCIDENT_FAILURE]: error => ({ error, isLoading: false }),
  [EVENT_ADD_TIME_SUCCESS]: ({ cs_release_at, time_extended }) => ({ cs_release_at, time_extended }),
  [INCIDENT_UPDATE]: incident => incident,
  [SET_AGENT_OPENED]: (state) => ({
    ...state,
    agentOpened: true,
  }),
});
