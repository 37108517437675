import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useStopTouchMove } from '../../utils/helpers';
import { useLinks } from '../../hooks/use-links';

const HelpfulLinks = () => {
  const touchmoveRef = useStopTouchMove();
  const helpfulLinks = useLinks();

  const list = Object.values(helpfulLinks);
  return (
    <div ref={touchmoveRef}>
      <Nav className={'HelpfulLinks--nav'} justified>
        {list.map(link => (
          <NavItem className={'HelpfulLinks--item'} key={link.title}>
            <NavLink target="_blank" rel="noopener noreferrer" className={'HelpfulLinks--link'} href={link.url}>
              {link.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </div>
  );
};

export default HelpfulLinks;
