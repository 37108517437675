import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import moment from 'moment';
import { Trans } from 'react-i18next';

export const PERSON_SHARED_VIDEO = 'person-shared-video';
export const EN_ROUTE = 'is-en-route';
export const FREE_FORM_NOTIFY = 'freeform-notify';
export const END_USER_PEOPLE_LOCATION = 'end-user-people-location';
export const END_USER_PROPERTY_DAMAGE = 'end-user-property-damage';
export const END_USER_CONCERN_FOR_SAFETY = 'end-user-concern-for-safety';
export const END_USER_UNSURE_OF_CAUSE = 'end-user-unsure-of-cause';


interface IProps {
  timestamp: Date | string;
  name: string;
  text: string;
  messageType?: MessageType;
}

export const Notification = ({ timestamp, name, messageType, text }: IProps) => {
  const { t } = useTranslation();

  let [, time] = text.split('|');

  const isLess = Number(time) <= 30;

  if (time == '0') {
    time = '5';
  }

  if (time == '60') {
    time = '30';
  }

  const timeline1 = (
    <>
      <div className={cx('PersonsActions--time', 'color-grey2')}>
        <strong>{name}</strong> {t(PERSON_SHARED_VIDEO)} {moment(timestamp).format('LT')}.
      </div>
    </>
  );

   const timeline2 = time === 'Not Responding' ? null : (() => {
    const formattedTime = typeof time === 'string'
      ? time.replace(/</g, 'less than ').replace(/(\d+) plus/g, 'more than $1')
      : '';

    return (
      <>
        <div className={cx('PersonsActions--time', 'color-grey2')}>
          <strong>{name}</strong> {
            time === 'Onsite'
            ? "is onsite."
            : t(EN_ROUTE, { time: formattedTime }) + "."
          }
        </div>
      </>
    );
  })();


  const [, freeFormText] = text.split('|');

  const timeline3 = (
    <>
      <div className={cx('PersonsActions--time', 'color-grey2')}>
        <strong>{name}</strong> {t(FREE_FORM_NOTIFY)}
      </div>
    </>
  );

  const timeline4 = (
    <>
      <div className={cx('PersonsActions--time', 'color-grey2')}>
        <strong>{name}</strong> {t(END_USER_PEOPLE_LOCATION)}
      </div>
    </>
  );

  const timeline5 = (
    <>
      <div className={cx('PersonsActions--time', 'color-grey2')}>
        <strong>{name}</strong> {t(END_USER_PROPERTY_DAMAGE)}
      </div>
    </>
  );

  const timeline6 = (
    <>
      <div className={cx('PersonsActions--time', 'color-grey2')}>
        <strong>{name}</strong> {t(END_USER_CONCERN_FOR_SAFETY)}
      </div>
    </>
  );

  const timeline7 = (
    <>
      <div className={cx('PersonsActions--time', 'color-grey2')}>
        <strong>{name}</strong> {t(END_USER_UNSURE_OF_CAUSE)}
      </div>
    </>
  );

  return (
    <>
      <div className={cx('PersonsActions--root')}>
        <div className={'PersonsActions--messageLine'}>
          {messageType === 'serviceShare' && <div className={'PersonsActions--timeline'}>{timeline1}</div>}
          {messageType === 'serviceEta' && <div className={'PersonsActions--timeline'}>{timeline2}</div>}
          {messageType === 'serviceFreeform' && <div className={'PersonsActions--timeline'}>{timeline3}</div>}
          {messageType === 'serviceLocation' && <div className={'PersonsActions--timeline'}>{timeline4}</div>}
          {messageType === 'serviceDamage' && <div className={'PersonsActions--timeline'}>{timeline5}</div>}
          {messageType === 'serviceSafety' && <div className={'PersonsActions--timeline'}>{timeline6}</div>}
          {messageType === 'serviceCause' && <div className={'PersonsActions--timeline'}>{timeline7}</div>}
        </div>
      </div>
    </>
  );
};

const PersonActionsMessage = ({ data, id, className, messageType }: IMessagePeace) => {
  const { timestamp, user, text } = data as IUserMessageData;
  return (
    <div id={id} className={className}>
      <Notification timestamp={timestamp} name={user.name} text={text} messageType={messageType} />
    </div>
  );
};

export default PersonActionsMessage;
