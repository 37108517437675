import { createAction, createEmptyAction } from '../utils/actions';

export interface IIncidentData {
  [key: string]: any;
}

export const FETCH_INCIDENT = 'INCIDENT/FETCH';
export const FETCH_INCIDENT_SUCCESS = 'INCIDENT/FETCH_SUCCESS';
export const FETCH_INCIDENT_UPDATE = 'INCIDENT/FETCH_UPDATE';
export const FETCH_INCIDENT_FAILURE = 'INCIDENT/FETCH_FAILURE';

export const types = {
  FETCH_INCIDENT,
  FETCH_INCIDENT_SUCCESS,
  FETCH_INCIDENT_FAILURE,
  FETCH_INCIDENT_UPDATE,
};

export const fetch = createEmptyAction(FETCH_INCIDENT);
export const fetchSuccess = createAction<IIncidentData>(FETCH_INCIDENT_SUCCESS);
export const fetchUpdate = createAction<IIncidentData>(FETCH_INCIDENT_UPDATE);
export const fetchFailure = createAction<Error>(FETCH_INCIDENT_FAILURE);

export default {
  fetch,
  fetchSuccess,
  fetchFailure,
};
