import PubNub from 'pubnub';

type MessageHandler = (message: any) => void;
type NetworkStatusChange = (status: 'up' | 'down') => void;

export class PubnubHandler {
  private pn: PubNub;
  private handler?: MessageHandler;
  private onUp?: NetworkStatusChange;
  private onDown?: NetworkStatusChange;
  constructor() {
    this.pn = new PubNub({
      publishKey: '',
      subscribeKey: '',
    });
  }
  configure(config: IIncidentAPI) {
    this.pn = new PubNub({
      publishKey: config.pn_publish_key,
      subscribeKey: config.pn_subscribe_key,
      uuid: PubNub.generateUUID(),
      autoNetworkDetection: true,
    });

    this.pn.addListener({
      status: statusEvent => {
        if (statusEvent.category === 'PNNetworkDownCategory' && this.onDown) {
          this.onDown('down');
        }
        if (statusEvent.category === 'PNNetworkUpCategory' && this.onUp) {
          this.onUp('up');
        }
      },
      message: this.messageHandler.bind(this),
    });
    this.pn.subscribe({
      channels: config.pn_channels_to_subscribe,
      withPresence: true,
    });
  }
  setHandler(handler: MessageHandler) {
    this.handler = handler;
  }

  setNetworkUpHandlers(onUp: NetworkStatusChange) {
    this.onUp = onUp;
  }
  setNetworkDownHandlers(onDown: NetworkStatusChange) {
    this.onDown = onDown;
  }

  messageHandler(pubNubData: PubNub.MessageEvent) {
    const { message } = pubNubData;
    //HERE IS PUBNUB LOG
    console.groupCollapsed(`PubNub - ${message.type}`);
    console.groupEnd();
    if (this.handler) {
      this.handler(message);
    }
  }

  publish(
    params: PubNub.PublishParameters,
    cb: (status: PubNub.PubnubStatus, response: PubNub.PublishResponse) => void,
  ) {
    if (this && this.pn) {
      return this.pn.publish(params, cb);
    }
  }
}

export default new PubnubHandler();
