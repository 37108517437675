import { helpfulLinksResiCom, helpfulLinksResidential, helpfulLinksCommercial } from '../constants';
import useCustomerType from '../hooks/use-customer-type';
import { CustomerType } from '../utils/helpers';

export const useLinks = () => {
  const { type } = useCustomerType();

  const linksMap: Record<CustomerType, typeof helpfulLinksResiCom> = {
    commercial: helpfulLinksCommercial,
    residential: helpfulLinksResidential,
    resicom: helpfulLinksResiCom,
    national: helpfulLinksResidential,
  };

  return linksMap[type];
};
